import { addToCompare, removeFromCompare } from "../../../../../redux/compareProductsReducer"
import { useLanguage } from "../../../../../lib/hooks/languageHooks"
import { addToFavoriteProducts, removeFromFavoriteProducts } from "../../../../../redux/favoriteSlice"
import { useIsUserAuthorized } from "../../../../../redux/hook/userHooks"
import FavoriteButton from "../../../../Favorites/FavoriteButton"
import CompareToggleButton from "../../../../CompareToggleButton/CompareToggleButton"
import ReportButton from "../../../ProductCard/ReportButton/ReportButton"
import React, { useMemo, useState } from "react"
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf"
import CompareIcon from "@mui/icons-material/Compare";
import { styled } from "@mui/styles"
import { useDispatch, useSelector } from "react-redux"
import axios from "axios"
import FavoriteIcon from "@mui/icons-material/Favorite"
import CircularProgress from "@mui/material/CircularProgress"

const Base = styled("div")(() => ({
    display: "flex",
    alignItems: "center"
}))

const StyledButton = styled("button")(({ active }) => ({
    border: 0,
    background: "none",
    cursor: "pointer",
    color: active ? "#ef7830" : "#2d2d2d"
}))

const ActionButtons = React.memo(({ productId }) => (
    <Base>
        <FavoriteButton productId={productId} />
        <CompareToggleButton productId={productId} />
        <ReportButton productId={productId} />
    </Base>
))

export default ActionButtons

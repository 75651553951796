import useStyles from "./styles"
import NewsCard from "./NewsCard"
import { strings as Localization } from "../../lib/Localization"
import { useLanguage } from "../../lib/hooks/languageHooks"
import { useGetNewsQuery } from "../../redux/services/broditWebApi"
import theme from "../../theme"
import { mobileMenuBreakpoint } from "../../lib/helper/viewportHelper"
import { useSteering } from "../../redux/hook/userHooks"
import { NavLink } from "react-router-dom"
import { Button, useMediaQuery } from "@mui/material"
import React, { useState } from "react"

function NewsCardList({ type, title }) {
    const classes = useStyles()
    const language = useLanguage()
    const isDesktop = useMediaQuery(theme.breakpoints.up(mobileMenuBreakpoint))
    const [seeMore, setSeeMore] = useState(false)
    const steering = useSteering()

    const groupByType = () => {
        let group = "device"

        if (type !== "device") {
            switch (steering) {
                case "1":
                    group = "LHD"
                    break
                case "2":
                    group = "RHD"
                    break
                default:
                    group = "LHD"
            }
        }

        return group
    }

    const allNewsPath = type !== "device" ? "proclip" : "news"

    const { data: newsArticles } = useGetNewsQuery({
        language,
        limit: 3,
        groups: [groupByType()]
    })

    const list = newsArticles?.length > 0
        ? Array.from(newsArticles)?.splice(0, isDesktop || seeMore ? 3 : 1)
        : []

    return (
        <div className={classes.wrapper} id="wrapper">
            <h2 className={classes.title}>{title}</h2>
            <div className={classes.list} id="list">
                {list.map((item, i) => <NewsCard item={item} type={type} language={language} key={item.id} index={i} />)}
            </div>
            {!isDesktop
                && (
                    <div className={classes.buttonWrapperRow}>
                        <Button className={classes.seeMoreButton} onClick={() => setSeeMore(!seeMore)}>
                            {seeMore ? Localization.seeLess : Localization.seeMore}
                        </Button>
                    </div>
                )}
            <div className={classes.buttonWrapperRow}>
                <NavLink className={classes.newsAnchor} to={`/${language}/${allNewsPath}`} end>{Localization.showMoreNews}</NavLink>
            </div>
        </div>
    )
}

export default NewsCardList

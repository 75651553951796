import useStyles from "./styles"
import { strings as Localization } from "../../lib/Localization"
import { addToCart } from "../../redux/cartSlice"
import { setSnackbarMessage } from "../../redux/userMessageSlice"
import { checkMonitorArticles } from "../../lib/helper/userHelper"
import { useLanguage } from "../../lib/hooks/languageHooks"
import { useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import { Button, TextField, Tooltip } from "@mui/material"
import React, { useEffect, useState, useRef } from "react"

const SpeedOrder = ({ articleNumber }) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const [loading, setLoading] = useState(false)
    const lang = useLanguage()
    const ref = useRef()

    const defaultValues = {
        articleNumber: null,
        quantity: 1
    }

    const {
        register, setValue, handleSubmit, watch
    } = useForm({ defaultValues: defaultValues })

    // First render
    useEffect(() => {
        setValue("articleNumber", articleNumber)
    }, [])

    // Handle a new search -> component is not mounted; but prop has changed.
    useEffect(() => {
        setValue("articleNumber", articleNumber)
    }, [articleNumber])

    const parse = i => parseInt(i, 10)

    const onSubmit = data => {
        checkMonitorArticles(lang, [data.articleNumber], result => {
            if (!result.includes(data.articleNumber) && data.quantity > 0) {
                const q = parse(data.quantity)
                dispatch(addToCart(data.articleNumber, q))
                dispatch(setSnackbarMessage(`${Localization.itemNumber} ${data.articleNumber} ${Localization.hasBeenAddedToCart}`, "success"))
            } else if (data.quantity === 0) {
                dispatch(setSnackbarMessage(Localization.articleCouldNotBeAddedQuantityZero, "error"))
            } else {
                dispatch(setSnackbarMessage(`${Localization.itemNumber} ${data.articleNumber} ${Localization.couldNotBePlacedInCart}`, "error"))
            }
        }, setLoading)

        ref.current?.focus()
    }

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            id={`quick-add-${articleNumber}`}
            style={{ display: "flex", justifyContent: "flex-end" }}
        >
            <TextField
                style={{ width: 75 }}
                variant="outlined"
                placeholder={Localization.qty}
                size="small"
                className={classes.qtyField}
                inputRef={ref}
                {...register("quantity", { required: true })}
            />
            <Tooltip title={Localization.addToCart}>
                <Button
                    size="small"
                    type="submit"
                    onClick={handleSubmit}
                    variant="contained"
                    color="primary"
                    disabled={!watch("articleNumber") || !watch("quantity") || loading}
                    classes={{
                        root: classes.buttonRoot,
                        outlinedSizeSmall: classes.outlinedSmall
                    }}
                    style={{ marginLeft: 0 }}
                >
                    {Localization.addToCart}
                </Button>
            </Tooltip>
        </form>
    )
}

export default SpeedOrder

import useStyles from "./styles"
import { strings as Localization } from "../../../../lib/Localization"
import { Typography } from "@mui/material"
import React from "react"
import classnames from "classnames"

export function ListProductNumber({ originalId }) {
    const classes = useStyles()
    return (
        <div>
            <Typography
                variant="h1"
                className={classnames(classes.h5Styling, classes.heading)}
                gutterBottom
            >
                {Localization.itemNumber}
            </Typography>
            <Typography variant="h2" className={classes.subtitle1Styling}>
                {originalId}
            </Typography>
        </div>
    )
}

export function GridProductNumber({ originalId }) {
    const classes = useStyles()
    return (
        <Typography variant="h2" className={classes.subtitle1Styling}>
            {Localization.itemNumber}
            {" "}
            {originalId}
        </Typography>
    )
}

import useStyles from "./styles"
import Assembly from "./assets/mont.png"
import AssemblyMob from "./assets/mont_mob.jpg"
import CNC from "./assets/cnc.jpg"
import CNCMob from "./assets/cnc_mob.jpg"
import { strings as Localization } from "../../lib/Localization"
import { useLanguage } from "../../lib/hooks/languageHooks"
import theme from "../../theme"
import { desktopBreakpoint, mobileMenuBreakpoint } from "../../lib/helper/viewportHelper"
import React from "react"
import {
    Box, Typography, useMediaQuery, Card, CardContent, CardMedia
} from "@mui/material"
import classnames from "classnames"
import { Link } from "react-router-dom"

const Career = () => {
    const classes = useStyles()
    const language = useLanguage()
    const isDesktop = useMediaQuery(theme.breakpoints.up(desktopBreakpoint))
    const isTablet = useMediaQuery(theme.breakpoints.down(mobileMenuBreakpoint))

    const test = [
        { text: "Gillar du CNC-maskiner och en välutrustad maskinpark? Det gör vi också! Vi söker nu engagerade CNC-operatörer till vår produktion. I denna roll kommer du att ställa och övervaka CNC-maskiner för att tillverka högkvalitativa komponenter inom plastindustrin." },
        { title: "Din profil", text: "Vi söker dig som tycker om att arbeta inom industrin. För att lyckas i rollen är det viktigt att du har god teknisk förståelse och kan arbeta noggrant. Du är flexibel, har god samarbetsförmåga och kan arbeta både självständigt och i grupp samt ta egna initiativ. Tidigare erfarenhet av liknande arbetsuppgifter är meriterande." },
        { title: "Vi erbjuder", text: "Vi erbjuder en arbetsmiljö där våra värdegrundsord Respekt, Utveckling, Engagemang, Mod och Målinriktning står i centrum. Vi vill skapa de bästa förutsättningarna för både vår personal och våra kunder genom att jobba mot dessa principer. Här får du möjligheten att bli en del av Broditfamiljen och vår framgångsrika och växande verksamhet." }
    ]

    const mont = [
        { text: "Vi söker nu flera engagerade personer till vår monteringsavdelning. Är du en praktiker med känsla för kvalitet? Då har vi jobbet för dig! Vi söker nu montörer inför höstens utmaningar. Arbetet innefattar bland annat montering av komponenter, packning samt kvalitetssäkring inom plastindustrin." },
        { title: "Din profil", text: "Vi söker dig som gillar att arbeta med händerna. För att lyckas i rollen är det viktigt att du förstår vikten av både tempo och noggrannhet. Du är flexibel, har god samarbetsförmåga och kan arbeta både självständigt och i grupp samt ta egna initiativ. Tidigare erfarenhet av liknande arbetsuppgifter är meriterande." },
        { title: "Vi erbjuder", text: "Vi erbjuder en arbetsmiljö där våra värdegrundsord Respekt, Utveckling, Engagemang, Mod och Målinriktning står i centrum. Vi vill skapa de bästa förutsättningarna för både vår personal och våra kunder genom att jobba mot dessa principer. Här får du möjligheten att bli en del av Broditfamiljen och vår framgångsrika och växande verksamhet." }
    ]

    const contacts = [
        {
            name: "Lena Johansson",
            country: "HR",
            phone: "0505-177 84",
            email: "lena@brodit.se",
            image: "/files/ImagesStatic/lena.jpg"
        }
    ]

    const renderImageTextSection = (title, textArray, imageSrc) => (
        <div className={classes.adWrapper}>
            <Box
                className={classes.textWrapper}
            >
                {isTablet
                    && (
                        <div className={classes.adImageWrapper}>
                            <img fetchPriority="high" className={classes.adImage} src={imageSrc} alt={Localization.adImageAlt} />
                        </div>
                    )}
                <h2 className={classes.blackH2}>
                    {title}
                </h2>

                {textArray.map((text, index) => (
                    <div className={classes.paragraph} key={index}>
                        {text.title && (
                            <h3 className={classes.blackH3} style={{ marginBottom: "unset", paddingBottom: "16px" }}>
                                {text.title}
                            </h3>
                        )}
                        <Typography className={classes.blackText} style={{ paddingBottom: "16px" }}>
                            {text.text}
                        </Typography>
                    </div>
                ))}

                <Typography className={classes.blackText} style={{ paddingBottom: "16px" }}>
                    För att läsa mer om Brodit och vår historia, tryck
                    {" "}
                    <Link style={{ color: "#2d2d2d" }} to={`/${language}/about`}>här</Link>
                </Typography>

                <Typography className={classes.blackText} style={{ paddingBottom: "16px" }}>
                    Tillträde enligt överenskommelse.
                    <br />
                    Urval sker löpande.
                </Typography>

                <Typography className={classes.blackText} style={{ paddingBottom: "16px" }}>
                    Skicka din ansökan till:
                    <br />
                    <strong>lena@brodit.se</strong>
                </Typography>
            </Box>
            {!isTablet
                && (
                    <div className={classes.adImageWrapper}>
                        <img fetchPriority="high" className={classes.adImage} src={imageSrc} alt={Localization.adImageAlt} />
                    </div>
                )}
        </div>
    )

    const renderContactCard = contact => (
        <Card
            className={classes.card}
        >
            <CardMedia
                fetchPriority="high"
                component="img"
                image={contact.image}
                alt={contact.name}
                className={classes.alignCenter}
            />
            <CardContent className={classes.cardContent}>
                <Typography className={classes.cardTitle}>
                    {contact.name}
                </Typography>
                <Typography className={classes.cardText}>
                    {contact.country}
                </Typography>
                <Typography>
                    <a
                        href={`tel: ${contact.phone}`}
                        className={classnames(classes.link, classes.anchor)}
                    >
                        {contact.phone}
                    </a>
                </Typography>
                <Typography>
                    <a
                        href={`mailto: ${contact.email}`}
                        className={classnames(classes.link, classes.anchor)}
                    >
                        {contact.email}
                    </a>
                </Typography>
            </CardContent>
        </Card>
    )

    const youtubeHero = () => (
        <Box className={classes.hero}>
            <iframe className={classes.youtube} src="https://www.youtube.com/embed/5uc-JS9ie3s?si=B1m2uODDLEJLWH5f&rel=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen />
        </Box>
    )

    return (
        <main className={classes.main}>
            <h1 className={classes.blackH1} style={{ marginTop: "50px" }}>Lediga tjänster</h1>
            <Box className={classes.section2}>
                <Box style={{
                    display: "flex", flexDirection: "column", alignItems: "center", width: isDesktop ? "1080px" : "100%"
                }}
                >
                    {renderImageTextSection(
                        "Monteringspersonal",
                        mont,
                        !isTablet ? Assembly : AssemblyMob
                    )}
                    {renderImageTextSection(
                        "CNC-Operatörer",
                        test,
                        !isTablet ? CNC : CNCMob
                    )}
                </Box>
            </Box>
            {youtubeHero()}
            <Box className={classes.section3} id="contact">
                <Box className={classes.section3Wrapper}>
                    <Typography variant="h2" className={classes.blackH2}>Ansök idag!</Typography>
                    {renderContactCard(contacts[0])}
                </Box>
            </Box>
        </main>
    )
}

export default Career

import MagnifyingGlass from "./assets/magnifying-glass.svg"
import useStyles from "./styles"
import { strings as Localization } from "../../lib/Localization"
import { setSearchQuery } from "../../redux/searchReducer"
import { useSearchQuery } from "../../redux/hook/searchHooks"
import { useSteering } from "../../redux/hook/userHooks"
import { clearSearchResult } from "../../redux/productReducer"
import { setShowNavbarSearch } from "../../redux/settingsReducer"
import { useLanguage } from "../../lib/hooks/languageHooks"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import React, { useEffect, useState } from "react"

const AsyncSearch = ({ style, ...props }) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [searchText, setSearchText] = useState("")
    const [_query, setQuery] = useState("")
    const [redirect, setRedirect] = useState(false)

    const asyncSearchSearchQuery = useSearchQuery("asyncSearch")
    const steering = useSteering()
    const language = useLanguage()
    const navigate = useNavigate()

    const handleEdit = e => {
        setSearchText(e.target.value)
    }

    const handleKeyUp = e => {
        if (e.keyCode === 13) {
            dispatch(setShowNavbarSearch(false))
            setRedirect(true)
            navigate(`/${language}/filter/`)
        }
    }

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            setQuery(searchText)
        }, 750)

        return () => clearTimeout(delayDebounceFn)
    }, [searchText])

    useEffect(() => () => {
        setRedirect(false)
    })

    const searchOnText = (ignoreSteering = false) => {
        const steeringFilter = { steering: [steering, "3"] }
        dispatch(setSearchQuery({
            type: "asyncSearch",
            value: (
                {
                    texts: [searchText.trim()],
                    ...(ignoreSteering ? {} : steeringFilter)
                }
            ),
            clearFilter: true
        }))
    }

    useEffect(() => {
        if (redirect) {
            // When the search term is a product number, ignore the steering.
            const isProductNumber = Boolean(
                searchText.trim().match(/^\d\d\d\d\d\d$/)
            )
            searchOnText(isProductNumber)
            navigate(`/${language}/filter`)
        }
    }, [searchText, redirect, dispatch])

    useEffect(() => {
        if (Object.entries(asyncSearchSearchQuery).length > 0) {
            dispatch(clearSearchResult())
            searchOnText()
        }
    }, [steering])

    return (
        <div className={classes.wrapper}>
            <div
                style={{
                    display: "flex", gap: 10, ...style
                }}
                {...props}
            >
                <img loading="lazy" src={MagnifyingGlass} width="19px" height="22px" alt="Search icon" />
                <div className={classes.searchInputWrapper}>
                    <input
                        className={classes.searchInput}
                        onKeyUp={handleKeyUp}
                        onChange={handleEdit}
                        value={searchText}
                        placeholder={Localization.searchForProducts}
                    />
                </div>
            </div>
        </div>
    )
}

export default AsyncSearch

import { useLanguage } from "../../../../lib/hooks/languageHooks"
import { strings as Localization } from "../../../../lib/Localization"
import React, { useState } from "react"
import { IconButton, Tooltip } from "@mui/material"
import { makeStyles } from "@mui/styles"
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf"
import axios from "axios"

const useStyles = makeStyles(() => ({
    iconButton: {
        padding: 4,
        color: "#2d2d2d"
    }
}))

const ReportButton = ({ productId }) => {
    const classes = useStyles()
    const lang = useLanguage()
    const [downloading, setDownloading] = useState(false)

    const downloadReport = () => {
        setDownloading(true);
        (async () => {
            let data
            let statusCode = 0

            try {
                await axios.get(`/api/ProductInfo/GetProductReport?id=${productId}&language=${lang}`, { responseType: "blob" })
                    .then(resp => {
                        data = resp.data
                        statusCode = resp.status
                    }).catch(err => {
                        if (err.response) {
                            statusCode = err.response.status
                        }
                    })
            } catch (error) {
                console.error("Error fetching data with axios")
            }

            setDownloading(false)

            if (statusCode !== 200) return

            const url = window.URL.createObjectURL(new Blob([data]))
            const link = document.createElement("a")
            link.href = url
            link.setAttribute("download", `${productId}_${lang}.pdf`)
            document.body.appendChild(link)
            link.click()
            link.parentNode.removeChild(link)
        })()
    }

    return (
        <Tooltip title="Skapa PDF-rapport">
            <div id="pdfButton">
                <IconButton
                    aria-label={Localization.downloadProductPdf}
                    className={classes.iconButton}
                    onClick={downloadReport}
                    disabled={downloading}
                >
                    <PictureAsPdfIcon />
                </IconButton>
            </div>
        </Tooltip>
    )
}

export default ReportButton

import { mobileBreakpoint } from "../../../../../lib/helper/viewportHelper"
import { strings as Localization } from "../../../../../lib/Localization"
import React, { useEffect, useRef, useState } from "react"
import { styled } from "@mui/styles"
import PlayArrowIcon from "@mui/icons-material/PlayArrow"
import { useMediaQuery } from "@mui/material"
import { useTheme } from "@emotion/react"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew"
import CloseIcon from "@mui/icons-material/Close"

const CarouselBase = styled("div")(() => ({
    display: "flex",
    flexWrap: "wrap",
    flex: "0 1 100%"
}))

const ImageWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    flex: "1 1 100%",
    overflowX: "hidden",
    scrollSnapType: "x mandatory",
    marginBottom: "16px",
    boxShadow: "0px 0px 20px 0px rgba(45, 45, 45, 0.1)",
    borderRadius: "6px",
    gap: "20px",
    [theme.breakpoints.down(mobileBreakpoint)]: {
        overflowX: "scroll",
        "&::-webkit-scrollbar": {
            display: "none"
        }
    }
}))

const SlideItem = styled("div")(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    flex: "0 0 100%",
    scrollSnapAlign: "center",
    minHeight: "500px",
    [theme.breakpoints.down(mobileBreakpoint)]: {
        minHeight: "225px"
    }
}))

const SlideImage = styled("img")(({ theme }) => ({
    height: "100%",
    width: "100%",
    objectFit: "contain",
    maxWidth: "100%",
    maxHeight: "500px",
    cursor: "zoom-in",
    alignSelf: "center",
    [theme.breakpoints.down(mobileBreakpoint)]: {
        cursor: "auto",
        maxHeight: "225px"
    }
}))

const ThumbnailWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    width: "50px",
    flex: "1 1 100%",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    gap: "5px",
    paddingLeft: "27px",
    paddingRight: "27px",
    [theme.breakpoints.down(mobileBreakpoint)]: {
        flexWrap: "unset",
        overflowX: "scroll",
        "&::-webkit-scrollbar": {
            display: "none"
        }
    }
}))

const LightboxThumbnailWrapper = styled(ThumbnailWrapper)(({ theme }) => ({
    justifyContent: "center"
}))

const Thumbnail = styled("div")(({ active }) => ({
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: "0 1 0",
    padding: "5px",
    cursor: "pointer",
    borderRadius: "4px",
    boxSizing: "border-box",
    border: active ? "2px solid #F09057" : "2px solid #e1e1e1"
}))

const ThumbnailImage = styled("img")(() => ({
    // flex: "1 1 auto",
    flex: "0 1 0",
    height: "60px",
    // maxWidth: "100%",
    objectFit: "cover"
}))

const Top = styled("div")(({ theme }) => ({
    display: "flex",
    flex: "0 1 100%",
    // background: "blue",
    height: "500px",
    alignItems: "center",
    gap: "5px",
    [theme.breakpoints.down(mobileBreakpoint)]: {
        height: "225px"
    }
}))

const NavigationButton = styled("button")(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px",
    backgroundColor: "transparent",
    border: 0,
    cursor: "pointer",
    height: "100%",
    transition: "all 0.2s ease-in",
    [theme.breakpoints.down(mobileBreakpoint)]: {
        display: "none"
    },
    "&:active svg": {
        transform: "scale(0.9)"
    }
}))

const Youtube = styled("iframe")(() => ({
    border: 0,
    width: "100%"
}))

const LightboxYoutube = styled(Youtube)(() => ({
    aspectRatio: "16 / 9"
}))

const Lightbox = styled("dialog")(() => ({
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.54)",
    border: 0,
    width: "100%",
    height: "100%",
    zIndex: 999999,
    padding: 0
}))

const LightboxWrapper = styled("div")(() => ({
    display: "flex",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center"
}))

const LightboxContent = styled("div")(() => ({
    position: "relative",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    padding: "50px 0px 20px 0",
    backgroundColor: "white",
    borderRadius: "6px",
    flex: "0 1 1400px",
    gap: "10px"
}))

const LightboxImage = styled("img")(() => ({
    maxWidth: "100%",
    minHeight: "500px",
    objectFit: "contain"
}))

const PlayBtnOverlay = styled("div")(() => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    width: "30px",
    height: "30px",
    background: "#F09057",
    borderRadius: "50%"
}))

const PlayBtnIcon = styled(PlayArrowIcon)(() => ({
    width: "20px",
    height: "20px",
    color: "#FFFFFF"
}))

const FullRow = styled("div")(() => ({
    display: "flex",
    flex: "0 1 100%",
    justifyContent: "center",
    alignItems: "center"
}))

const TopContent = styled("div")(() => ({
    display: "flex",
    flex: "0 1 100%",
    justifyContent: "space-between",
    alignItems: "center"
}))

const CloseButton = styled("button")(() => ({
    position: "absolute",
    top: 0,
    right: 0,
    background: "transparent",
    border: 0,
    padding: "20px",
    cursor: "pointer"
}))

const ProductImageCarousel = React.memo(({
    videos, product, playVideo, toggleVideo
}) => {
    const theme = useTheme()
    const sliderRef = useRef()
    const imageRefs = useRef([])
    const [activeIndex, setActiveIndex] = useState(0)
    const [activeThumbnail, setActiveThumbnail] = useState(0)
    const [media, setMedia] = useState([])
    const [lightboxOpen, setLightboxOpen] = useState(false)
    const isMobile = useMediaQuery(theme.breakpoints.down(mobileBreakpoint))

    useEffect(() => {
        const images = []
        const instructionImages = []

        product.images.forEach(image => {
            /-[0-9]+\.[a-zA-Z0-9]+$/.test(image) ? instructionImages.push(image) : images.push(image)
        })

        images.sort((a, b) => a.length - b.length)

        const items = images.map((image, i) => (
            {
                id: `image-${i}`,
                url: image,
                type: "image"
            }
        ))

        if (instructionImages) {
            instructionImages.forEach((image, i) => items.push({
                id: `image-instr-${i}`,
                url: image,
                type: "image"
            }))
        }

        if (videos && videos.length > 0) {
            items.push(...videos.map((video, i) => (
                {
                    id: `video-${i}`,
                    url: video,
                    type: "youtube"
                }
            )))
        }

        setMedia(items)
    }, [videos, product.images])

    useEffect(() => {
        imageRefs.current = []
        setActiveIndex(0)
        setActiveThumbnail(0)
    }, [product])

    const scrollImageIntoView = index => {
        if (!isMobile) {
            setActiveThumbnail(index)
        }

        setActiveIndex(index)

        imageRefs.current[index].scrollIntoView({ block: "nearest", inline: "nearest" })
    }

    useEffect(() => {
        if (playVideo) {
            scrollImageIntoView(imageRefs.current.length - 1)
            toggleVideo(!playVideo)
        }
    }, [playVideo])

    const previousSlide = () => {
        setActiveIndex(prevIndex => {
            const newIndex = prevIndex > 0 ? prevIndex - 1 : imageRefs.current.length - 1
            scrollImageIntoView(newIndex)
            return newIndex
        })
    }

    const nextSlide = () => {
        setActiveIndex(prevIndex => {
            const newIndex = prevIndex < imageRefs.current.length - 1 ? prevIndex + 1 : 0
            scrollImageIntoView(newIndex)
            return newIndex
        })
    }

    const getYoutubeThumbnailUrl = embedUrl => {
        const videoId = embedUrl.match(/embed\/([a-zA-Z0-9_-]{11})/)[1]
        return `https://img.youtube.com/vi/${videoId}/default.jpg`
    }

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: "0px",
            threshold: 1.0
        }

        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting && isMobile) {
                    setActiveThumbnail(+entry.target.dataset.index)
                }
            })
        }, options)

        imageRefs.current.forEach(ref => {
            if (ref) observer.observe(ref)
        })

        return () => observer.disconnect()
    }, [media])

    const handleKeyDown = e => {
        if (imageRefs.current.length > 0) {
            switch (e.key) {
                case "ArrowRight":
                    e.preventDefault()
                    nextSlide()
                    break
                case "ArrowLeft":
                    e.preventDefault()
                    previousSlide()
                    break
                case "Escape":
                    e.preventDefault()
                    setLightboxOpen(false)
                    break
                default:
                    break
            }
        }
    }

    useEffect(() => {
        window.addEventListener("keyup", handleKeyDown)
        return () => {
            window.removeEventListener("keyup", handleKeyDown)
        }
    }, [])

    const renderLightboxImageOrVideo = item => {
        if (item) {
            if (item?.type === "youtube") {
                return <LightboxYoutube title="video" src={item?.url} />
            }
            return (
                <div>
                    <LightboxImage loading="lazy" src={`${process.env.REACT_APP_IMAGE_URL}${item?.url}`} alt="full size" />
                </div>
            )
        }
        return null
    }

    return (
        <CarouselBase>
            <Top>
                {media.length > 0
                    && (
                        <>
                            {media.length > 1 && <NavigationButton aria-label={Localization.previousProductImage} onClick={previousSlide} type="button"><ArrowBackIosNewIcon /></NavigationButton>}
                            <ImageWrapper ref={sliderRef}>
                                {media.map((item, i) => (
                                    <SlideItem key={`image-${item.id}`}>
                                        {(item.type === "youtube")
                                            ? <Youtube data-index={i} title="video" ref={element => { if (element) imageRefs.current[i] = element }} src={item.url} />
                                            : <SlideImage data-index={i} loading="lazy" onClick={() => !isMobile && setLightboxOpen(true)} ref={element => { if (element) imageRefs.current[i] = element }} src={`${process.env.REACT_APP_IMAGE_URL}${item.url}`} alt={item.id} />}
                                    </SlideItem>
                                ))}
                            </ImageWrapper>
                            {media.length > 1 && <NavigationButton aria-label={Localization.nextProductImage} onClick={nextSlide} type="button"><ArrowForwardIosIcon /></NavigationButton>}
                        </>
                    )}
            </Top>
            <ThumbnailWrapper>
                {media.map((item, i) => (
                    <Thumbnail active={i === activeThumbnail ? 1 : 0} onClick={() => scrollImageIntoView(i)} key={`thumbnail-${item.id}`}>
                        {item.type === "youtube" && <PlayBtnOverlay><PlayBtnIcon /></PlayBtnOverlay>}
                        <ThumbnailImage loading="lazy" src={item.type === "youtube" ? getYoutubeThumbnailUrl(item.url) : `${process.env.REACT_APP_IMAGE_URL}w120/${item.url}`} alt={item.id} />
                    </Thumbnail>
                ))}
            </ThumbnailWrapper>
            <Lightbox open={lightboxOpen}>
                <LightboxWrapper onClick={() => setLightboxOpen(false)}>
                    <LightboxContent onClick={e => e.stopPropagation()}>
                        <TopContent>
                            {media.length > 1 ? <NavigationButton onClick={previousSlide} type="button"><ArrowBackIosNewIcon /></NavigationButton> : <div />}
                            {media.length > 0
                                && renderLightboxImageOrVideo(media[activeIndex])}
                            {media.length > 1 ? <NavigationButton onClick={nextSlide} type="button"><ArrowForwardIosIcon /></NavigationButton> : <div />}
                        </TopContent>
                        {media.length > 1
                            && (
                                <FullRow>
                                    <LightboxThumbnailWrapper>
                                        {media.map((item, i) => (
                                            <Thumbnail
                                                active={i === activeThumbnail ? 1 : 0}
                                                onClick={() => scrollImageIntoView(i)}
                                                key={`thumbnail-${item.id}`}
                                            >
                                                {item.type === "youtube" && <PlayBtnOverlay><PlayBtnIcon /></PlayBtnOverlay>}
                                                <ThumbnailImage loading="lazy" src={item.type === "youtube" ? getYoutubeThumbnailUrl(item.url) : `${process.env.REACT_APP_IMAGE_URL}w120/${item.url}`} alt={item.id} />
                                            </Thumbnail>
                                        ))}
                                    </LightboxThumbnailWrapper>
                                </FullRow>
                            )}
                        <CloseButton onClick={() => setLightboxOpen(false)}><CloseIcon /></CloseButton>
                    </LightboxContent>
                </LightboxWrapper>
            </Lightbox>
        </CarouselBase>
    )
})

export default ProductImageCarousel

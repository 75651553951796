/* eslint-disable import/no-cycle */
/* eslint-disable react/no-array-index-key */
import ProductImageCarousel from "./ProductImageCarousel/ProductImageCarousel"
import ProductAccordion from "./ProductAccordion/ProductAccordion"
import CompatibleModelsList from "./CompatibleModelsList/CompatibleModelsList"
import Accessories from "./Accessories/Accessories"
import ActionButtons from "./ActionButtons/ActionButtons"
import ModTekSection from "./ModTekSection/ModTekSection"
import AddToCartControls from "./AddToCartControls/AddToCartControls"
import Material from "./Material"
import {
    useGetModTekProductsQuery, useGetRelatedProductsQuery, useGetRelatedVideosQuery, useGetTechnicalInformationForProductQuery
} from "../../../../redux/services/broditWebApi"
import { useLanguage } from "../../../../lib/hooks/languageHooks"
import { strings as Localization } from "../../../../lib/Localization"
import Specification from "../view/Specification"
import Instructions from "../view/Instructions"
import { useProduct } from "../../../../redux/hook/productHooks"
import usedTheme from "../../../../theme"
import { mobileBreakpoint } from "../../../../lib/helper/viewportHelper"
import { useIsUserAuthorized } from "../../../../redux/hook/userHooks"
import Materials from "../view/Materials"
import {
    useMediaQuery
} from "@mui/material"
import { styled } from "@mui/styles"
import React, { useState } from "react"
import { Link, useParams } from "react-router-dom"
import PlayArrowIcon from "@mui/icons-material/PlayArrow"
import CircularProgress from "@mui/material/CircularProgress"

const Base = styled("div")(() => ({
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap"
}))

const Wrapper = styled("div")(({ theme }) => ({
    display: "flex",
    flex: "0 1 1450px",
    flexWrap: "wrap",
    justifyContent: "space-between",
    paddingTop: "20px",
    paddingBottom: "60px",
    [theme.breakpoints.down(1400)]: {
        justifyContent: "center"
    }
}))

const Left = styled("div")(() => ({
    display: "flex",
    flex: "0 1 950px",
    flexWrap: "wrap",
    alignContent: "flex-start"
}))

const Right = styled("div")(() => ({
    display: "flex",
    flexWrap: "wrap",
    flex: "0 1 460px",
    alignContent: "flex-start",
    gap: "25px"
}))

const ProductInfoWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    flexWrap: "wrap",
    flex: "0 1 100%",
    backgroundColor: "#F8F8F8",
    boxShadow: "0px 0px 20px 0px rgba(45, 45, 45, 0.1)",
    borderRadius: "6px",
    padding: "40px",
    [theme.breakpoints.down(mobileBreakpoint)]: {
        padding: "30px 10px"
    }
}))

const ProductName = styled("div")(() => ({
    flex: "0 1 100%",
    fontFamily: "Rubik",
    fontSize: "25px",
    fontWeight: 500,
    lineHeight: "40px"
}))

const ProductId = styled("div")(() => ({
    flex: "0 1 100%",
    fontFamily: "Arial",
    fontSize: "18px",
    fontWeight: 700,
    letterSpacing: "0.5px",
    marginBottom: "16px"
}))

const ProductDescription = styled("p")(() => ({
    flex: "0 1 100%",
    fontFamily: "Arial",
    fontSize: "17px",
    fontWeight: 400,
    lineHeight: "24px",
    letterSpacing: "0.5px",
    marginTop: 0
}))

const ProductVideoLink = styled("a")(() => ({
    display: "flex",
    gap: "10px",
    fontFamily: "Arial",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    letterSpacing: "0.5px",
    alignItems: "center",
    cursor: "pointer",
    marginBottom: "16px",
    "& span": {
        paddingTop: "2px",
        borderBottom: "1px solid transparent"
    },
    "&:hover span": {
        borderBottom: "1px solid #F09057"
    }
}))

const FiftyFifty = styled("div")(() => ({
    display: "flex",
    flex: "0 1 100%",
    justifyContent: "space-between",
    flexWrap: "wrap",
    gap: "8px"
}))

const RetailerLink = styled(Link)(() => ({
    padding: "10px 20px",
    backgroundColor: "#F09057",
    color: "#2D2D2D",
    borderRadius: "4px",
    textDecoration: "none",
    fontFamily: "Arial",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "16px",
    letterSpacing: "0.5px",
    "&:hover": {
        backgroundColor: "#ef7830"
    }
}))

const VideoIcon = styled("div")(() => ({
    width: "22px",
    height: "22px",
    borderRadius: "50%",
    backgroundColor: "#F09057",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white"
}))

const AccordionWrapper = styled("div")(({ theme }) => ({
    flex: "0 1 100%",
    padding: "0 22px",
    [theme.breakpoints.down(mobileBreakpoint)]: {
        padding: "0 10px"
    }
}))

const LoadingWrapper = styled("div")(() => ({
    display: "flex",
    flex: "0 1 100%",
    padding: "20px",
    justifyContent: "center",
    flexWrap: "wrap"
}))

const LoadingTop = styled("div")(() => ({
    display: "flex",
    flex: "0 1 100%",
    padding: "20px",
    justifyContent: "center"
}))

const LoadingText = styled("div")(() => ({
    display: "flex",
    flex: "0 1 100%",
    padding: "20px",
    justifyContent: "center",
    fontFamily: "Arial",
    fontSize: "20px",
    fontWeight: 700
}))

const Spinner = styled(CircularProgress)(() => ({
    width: "100px",
    height: "100px"
}))

const InformationRow = styled("div")(() => ({
    flex: "0 1 100%",
    fontFamily: "Arial",
    fontSize: "17px",
    fontWeight: 400,
    lineHeight: "24px",
    letterSpacing: "0.5px",
    color: "#c30010",
    whiteSpace: "pre-line",
    marginBottom: "16px"
}))

const MaterialWrapper = styled("div")(() => ({
    display: "flex",
    flexWrap: "wrap",
    flex: "0 1 100%",
    marginTop: "16px"
}))

const DesktopProductInfoNew = () => {
    const { id } = useParams()
    const product = useProduct(id)
    const { data: technicalInformation } = useGetTechnicalInformationForProductQuery(id)
    const { data: modTek } = useGetModTekProductsQuery(id)
    const { data: videos } = useGetRelatedVideosQuery(id)
    const isDesktop = useMediaQuery(usedTheme.breakpoints.up(1400))
    const language = useLanguage()
    const [playVideo, setPlayVideo] = useState(false)
    const { data: relatedProductIds } = useGetRelatedProductsQuery(id)
    const isAuthorized = useIsUserAuthorized()

    const toggleVideo = () => {
        setPlayVideo(!playVideo)
    }

    const getTechnicalInformation = () => (
        technicalInformation
            ? technicalInformation
                ?.find(ti => ti.languageShort === language)
                ?.htmlText
            : product?.techincalInformation)

    const renderProductInfo = () => (
        <>
            <ProductInfoWrapper>
                {product && (
                    <>
                        <ProductName>{product.name}</ProductName>
                        <ProductId>
                            Art.
                            {id}
                        </ProductId>
                        {product?.importantInformation && <InformationRow>{product.importantInformation}</InformationRow>}
                        {product?.description
                            && (
                                <ProductDescription>
                                    {product.description}
                                </ProductDescription>
                            )}
                        {modTek?.information
                        && (
                            <InformationRow>
                                {language === "sv" ? modTek.information.sv : modTek.information.en}
                            </InformationRow>
                        )}
                        {videos?.length > 0
                            && (
                                <ProductVideoLink onClick={toggleVideo}>
                                    <VideoIcon><PlayArrowIcon style={{ height: "16px" }} /></VideoIcon>
                                    <span>{Localization.seeProductVideo}</span>
                                </ProductVideoLink>
                            )}
                        <FiftyFifty>
                            {isAuthorized
                                ? <AddToCartControls productId={product.originalId} />
                                : <RetailerLink to={`/${language}/static/findRetailers`}>{Localization.findReseller}</RetailerLink>}

                            <ActionButtons productId={product.originalId} />

                            {isDesktop
                                && <CompatibleModelsList models={product.compatibleModels} />}
                        </FiftyFifty>
                    </>
                )}
            </ProductInfoWrapper>
            {modTek && modTek.categories
                && (
                    <ProductInfoWrapper>
                        <ModTekSection modTek={modTek} />
                    </ProductInfoWrapper>
                )}
        </>
    )

    const renderMaterials = () => (
        <MaterialWrapper>
            {product.materials.map((material, i) => (
                <Material key={`mat-${i}`} id={material} />
            ))}
        </MaterialWrapper>
    )

    return product ? (
        <Base>
            <Wrapper>
                <Left>
                    <ProductImageCarousel product={product} videos={videos} playVideo={playVideo} toggleVideo={toggleVideo} />
                    {!isDesktop && renderProductInfo()}
                    <AccordionWrapper>
                        {!isDesktop && !product.compatibleModels.some(model => model.brand === ",")
                            && (
                                <ProductAccordion title={Localization.fitsFor}>
                                    <CompatibleModelsList models={product.compatibleModels} />
                                </ProductAccordion>
                            )}
                        {(technicalInformation || product?.techincalInformation)
                            && (
                                <ProductAccordion
                                    defaultExpanded
                                    title={Localization.tabInformation}
                                    text={getTechnicalInformation()}
                                >
                                    {product.materials && renderMaterials()}
                                </ProductAccordion>
                            )}
                        <ProductAccordion title={Localization.tabSpecification}>
                            <Specification id={id} />
                        </ProductAccordion>
                        {product?.instruction
                            && (
                                <ProductAccordion title={Localization.tabInstructions}>
                                    <Instructions images={product.images} text={product.instruction} />
                                </ProductAccordion>
                            )}
                    </AccordionWrapper>
                </Left>
                {isDesktop
                    && (
                        <Right>{renderProductInfo()}</Right>
                    )}
            </Wrapper>
            {relatedProductIds && relatedProductIds.length > 0
                && <Accessories relatedProductIds={relatedProductIds} />}
        </Base>
    ) : (
        <LoadingWrapper>
            <LoadingTop>
                <Spinner />
            </LoadingTop>
            <LoadingText>
                {Localization.loadingProduct}
            </LoadingText>
        </LoadingWrapper>
    )
}

export default DesktopProductInfoNew

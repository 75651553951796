import ArrowUp from "../assets/arrow-up.svg"
import React, { useState, useEffect } from "react"
import { styled } from "@mui/styles"

const RootButton = styled("button")(({ visible }) => (
    {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "50px",
        height: "50px",
        borderRadius: "50%",
        position: "fixed",
        top: "100%",
        right: "35px",
        backgroundColor: "#F09057",
        transform: "translateY(-100%) translateY(-35px)",
        border: 0,
        cursor: visible ? "pointer" : "auto",
        transition: "all 0.2s ease-in",
        opacity: visible ? "1" : "0",
        zIndex: 9999,
        "&:hover": {
            backgroundColor: "#ef7830"
        }
    }
))

const Arrow = styled("img")(() => (
    { width: "20px", height: "23px" }
))

const ScrollTopButton = () => {
    const [visible, setVisible] = useState(false)

    const toggleVisibility = () => {
        const scrollTop = document.documentElement.scrollTop
        setVisible(scrollTop > 300)
    }

    const scrollTop = () => {
        if (visible) window.scrollTo({ top: 0, behavior: "smooth" })
    }

    useEffect(() => {
        window.addEventListener("scroll", toggleVisibility)

        return () => {
            window.removeEventListener("scroll", toggleVisibility)
        }
    }, [])

    return (
        <RootButton visible={visible ? "true" : undefined} onClick={scrollTop}>
            <Arrow alt="Arrow up" src={ArrowUp} />
        </RootButton>
    )
}

export default ScrollTopButton

import useStyles from "./styles"
import {
    increasePageLimit, setPageLimit, setPageOffset, setUserLimit, resetCurrentPage
} from "../../redux/pageReducer"
import { strings as Localization } from "../../lib/Localization"
import usePage from "../../redux/hook/pageHook"
import { useSearchQuery } from "../../redux/hook/searchHooks"
import DropdownPagination from "../Pagination/DropdownPagination"
import PagePagination from "../Pagination/PagePagination"
import { setSearchQuery } from "../../redux/searchReducer"
import CachedIcon from "@mui/icons-material/Cached"
import { useDispatch } from "react-redux"
import {
    Box, Button, MenuItem, Select
} from "@mui/material"
import React, { useEffect, useRef } from "react"
import classNames from "classnames"
import { useLocation, useNavigate } from "react-router-dom"

const PRODS_PER_PAGE = 20

const PageHandler = ({ type, itemCount }) => {
    const page = usePage()
    const classes = useStyles()
    const dispatch = useDispatch()
    const searchQuery = useSearchQuery()
    const index = Math.floor(page.offset / page.userSelectedLimit)
    const amountOfPages = Math.ceil(itemCount / page.userSelectedLimit)

    const location = useLocation()
    const navigate = useNavigate()
    const prevLocation = useRef(location.pathname + location.search)
    const prevSearchQuery = useRef(null)

    const match = location.search.match(/(?:\?|&)texts=([^&]*)/)
    const textsParameter = match ? match[1] : ""

    const reset = () => {
        dispatch(setPageOffset(0))
        dispatch(resetCurrentPage())
    }

    const handleLoadSpecific = pageIndex => {
        dispatch(setPageOffset(pageIndex * page.userSelectedLimit))
        dispatch(setPageLimit(page.userSelectedLimit))

        const url = new URL(window.location.href)
        const params = new URLSearchParams(url.search)

        params.set("page", pageIndex)

        url.search = params.toString()

        dispatch(setSearchQuery({ type: "filterProducts", value: { ...searchQuery, page: [url.search.split("page=")[1]] } }))
        if (window.history.href !== "https://localhost:5031/en/filter") navigate(url.pathname + url.search)
    }

    useEffect(() => {
        if (searchQuery.brand === undefined) return
        const currentLocation = location.pathname + location.search
        const hasSearchQueryChanged = searchQuery !== prevSearchQuery.current

        if (searchQuery?.texts?.[0] !== undefined) {
            const shouldResetPage = (
                currentLocation !== prevLocation.current
                || (!location.pathname.includes(searchQuery.brands)
                    && !decodeURIComponent(textsParameter).replace(/\+/g, " ").includes(searchQuery?.texts?.[0]))
            )

            if (shouldResetPage) reset()
        }

        if (hasSearchQueryChanged && searchQuery.page === undefined) {
            reset()
        } else {
            handleLoadSpecific(parseInt(searchQuery.page, 10))
        }

        prevLocation.current = currentLocation
        prevSearchQuery.current = searchQuery
    }, [location, dispatch, searchQuery])

    const onLoadMore = () => {
        dispatch(increasePageLimit())
    }

    const handleListSelect = e => {
        dispatch(setPageLimit(e.target.value))
        dispatch(setUserLimit(e.target.value))
    }

    if (type === "list") {
        const numberOfOptions = 3
        return (
            <Select inputProps={{ "aria-label": Localization.selectOption }} onChange={handleListSelect} value={page.userSelectedLimit} className={classes.select} variant="standard">
                {Array.from(Array(numberOfOptions)).map((x, i) => {
                    const value = (i * PRODS_PER_PAGE) + PRODS_PER_PAGE
                    return <MenuItem value={value} key={i}>{value}</MenuItem>
                })}
            </Select>
        )
    }

    if (type === "button") {
        return (
            <div className={classes.root}>
                <Button
                    className={classes.button}
                    fullWidth
                    variant="outlined"
                    startIcon={<CachedIcon />}
                    onClick={onLoadMore}
                >
                    {Localization.loadMore}
                </Button>
            </div>
        )
    }

    if (type === "pagePagination") {
        let pageOptions = [...Array(amountOfPages)].map(
            (_, i) => i + 1
        )

        if (amountOfPages <= 0) pageOptions = [1]

        return (
            <Box className={classNames(classes.root, classes.centerContent)}>
                <PagePagination
                    values={pageOptions}
                    setIndex={handleLoadSpecific}
                    index={!Number.isNaN(parseInt(new URLSearchParams(new URL(window.location.href).search).get("page"), 10) - 1) ? parseInt(new URLSearchParams(new URL(window.location.href).search).get("page"), 10) - 1 : index}
                />
            </Box>
        )
    }

    if (type === "dropdownPagination") {
        let dropdownOptions = [...Array(amountOfPages)].map(
            (_, i) => {
                const step = page.userSelectedLimit
                const start = step * i
                let end = start + (step)
                if (end > itemCount) end = itemCount
                return `${start + 1}-${end}`
            }
        )

        if (amountOfPages <= 0) {
            dropdownOptions = ["0-0"]
        }

        return (
            <DropdownPagination
                values={dropdownOptions}
                setIndex={handleLoadSpecific}
                index={!Number.isNaN(parseInt(new URLSearchParams(new URL(window.location.href).search).get("page"), 10) - 1) ? parseInt(new URLSearchParams(new URL(window.location.href).search).get("page"), 10) - 1 : index}
                itemCountText={itemCount}
            />
        )
    }

    return null
}

export default PageHandler

import ActionButtons from "../ActionButtons/ActionButtons"
import { useProducts } from "../../../../../redux/hook/productHooks"
import { mobileBreakpoint } from "../../../../../lib/helper/viewportHelper"
import { useLanguage } from "../../../../../lib/hooks/languageHooks"
import { strings as Localization } from "../../../../../lib/Localization"
import { useIsUserAuthorized } from "../../../../../redux/hook/userHooks"
import AddToCartControls from "../AddToCartControls/AddToCartControls"
import React from "react"
import { styled } from "@mui/styles"
import { Link } from "react-router-dom"
import NoPhotographyOutlinedIcon from "@mui/icons-material/NoPhotographyOutlined";

const Base = styled("div")(() => ({
    display: "flex",
    flex: "0 0 100%",
    flexWrap: "wrap",
    backgroundColor: "#F8F8F8",
    padding: "60px 0",
    justifyContent: "center"
}))

const Title = styled("h2")(() => ({
    flex: "0 1 100%",
    fontFamily: "Rubik",
    fontSize: "30px",
    fontWeight: 500,
    lineHeight: "40px",
    textAlign: "center"
}))

const AccessoryGrid = styled("div")(({ theme }) => ({
    display: "grid",
    justifyContent: "center",
    gridTemplateColumns: "420px 420px 420px",
    gap: "20px",
    [theme.breakpoints.down(1350)]: {
        gridTemplateColumns: "420px 420px"
    },
    [theme.breakpoints.down(950)]: {
        gridTemplateColumns: "1fr 1fr"
    },
    [theme.breakpoints.down(mobileBreakpoint)]: {
        gridTemplateColumns: "320px"
    }
}))

const AccessoryGridItem = styled("div")(() => ({
    display: "flex",
    gap: "5px",
    flexWrap: "wrap",
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 0px 20px 0px #2D2D2D1A",
    borderRadius: "6px",
    padding: "10px"
}))

const ImageWrapper = styled(Link)(() => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: "0 1 100%",
    height: "200px"
}))

const Image = styled("img")(() => ({
    maxWidth: "100%",
    maxHeight: "200px"
}))

const NoImage = styled(NoPhotographyOutlinedIcon)(() => ({
    width: "100%",
    height: "100px",
    opacity: 0.2
}))

const BottomWrapper = styled("div")(() => ({
    display: "flex",
    flex: "0 1 100%",
    flexWrap: "wrap",
    padding: "5px 10px",
    gap: "30px"
}))

const ButtonWrapper = styled("div")(({ authorized }) => ({
    display: "flex",
    flex: "0 1 100%",
    justifyContent: "space-between",
    alignItems: authorized ? "flex-end" : "center"
}))

const ItemText = styled("div")(() => ({
    flex: "0 1 100%",
    fontFamily: "Arial",
    fontSize: "17px",
    fontWeight: 400,
    lineHeight: "24px",
    letterSpacing: "0.5px"
}))

const ItemLink = styled(Link)(() => ({
    fontFamily: "Arial",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "16px",
    letterSpacing: "0.5px",
    backgroundColor: "#F09057",
    textDecoration: "none",
    padding: "10px 20px",
    color: "#2D2D2D",
    borderRadius: "4px",
    "&:hover": {
        backgroundColor: "#ef7830"
    }
}))

const Accessories = ({ relatedProductIds }) => {
    const relatedProducts = useProducts(relatedProductIds || [], "related")
    const language = useLanguage()
    const isAuthorized = useIsUserAuthorized()

    const scrollTop = () => {
        window.scrollTo(0,0)
    }

    return relatedProducts && relatedProducts.length > 0 && (
        <Base>
            <Title>{Localization.tabRelatedProducts}</Title>
            <AccessoryGrid>
                {relatedProducts && relatedProducts.map((product, i) => (
                    <AccessoryGridItem key={`acc-${i}`}>
                        <ImageWrapper onClick={scrollTop} to={`/${language}/product/${product.originalId}`}>
                            <Image loading="lazy" src={`${process.env.REACT_APP_IMAGE_URL}w450/${product.originalId}.jpg`} alt="product" />
                        </ImageWrapper>
                        <BottomWrapper>
                            <ItemText>{product.name}</ItemText>
                            <ButtonWrapper authorized={isAuthorized ? "1" : "0"}>
                                {isAuthorized
                                    ? (
                                        <>
                                            <AddToCartControls productId={product.originalId} />
                                            <ActionButtons productId={product.originalId} />
                                        </>
                                    )
                                    : (
                                        <>
                                            <ActionButtons productId={product.originalId} />
                                            <ItemLink onClick={scrollTop} to={`/${language}/product/${product.originalId}`}>{`Art. ${product.originalId}`}</ItemLink>
                                        </>
                                    )}
                            </ButtonWrapper>
                        </BottomWrapper>
                    </AccessoryGridItem>
                ))}
            </AccessoryGrid>
        </Base>
    )
}

export default Accessories

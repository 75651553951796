import { mobileBreakpoint } from "../../../lib/helper/viewportHelper"
import { makeStyles } from "@mui/styles"

export default makeStyles(theme => ({
    wrapper: {
        display: "flex",
        alignItems: "center"
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3)
    },
    infoArea: {
        marginBottom: 50
    },
    listArea: {
        backgroundColor: theme.palette.background.default,
        paddingRight: theme.spacing(2),
        paddingBottom: 0,
        [theme.breakpoints.down(mobileBreakpoint)]: {
            padding: 0
        }
    },
    flexColumn: {
        display: "flex",
        flexDirection: "column"
    },
    select: {
        minWidth: "275px",
        width: "auto",
        [theme.breakpoints.down(mobileBreakpoint)]: {
            minWidth: "220px"
        }
    }
}))

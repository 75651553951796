import useStyles from "../styles"
import { useGetHeroNewsQuery } from "../../../redux/services/broditWebApi"
import { strings as Localization } from "../../../lib/Localization"
import { tabletBreakpoint } from "../../../lib/helper/viewportHelper"
import theme from "../../../theme"
import { useLanguage } from "../../../lib/hooks/languageHooks"
import React, { useEffect, useRef, useState } from "react"
import { Button, Typography, useMediaQuery } from "@mui/material"
import { Link } from "react-router-dom"
import classnames from "classnames"

function HeroNews() {
    const { data: hNews } = useGetHeroNewsQuery()
    const language = useLanguage()
    const heroNews = hNews?.filter(x => x.language === language && x.active === true)
    const textRef = useRef()
    const [fadeIn, setFadeIn] = useState(true)
    const [selectedHeroIndex, setSelectedHeroIndex] = useState(0)
    const fadeInRef = useRef(0)
    const classes = useStyles()
    const isTablet = useMediaQuery(theme.breakpoints.up(tabletBreakpoint))

    const handleSelectHero = index => {
        setFadeIn(false)
        setTimeout(() => {
            fadeInRef.current += 1
            setSelectedHeroIndex(index)
            setFadeIn(true)
        }, 500) // Set the timeout to match the animation duration
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (heroNews?.length > 1) {
                setFadeIn(false)
                setTimeout(() => {
                    fadeInRef.current += 1
                    const len = heroNews?.length || 0
                    setSelectedHeroIndex(prevIndex => ((prevIndex + 1) % len))
                    setFadeIn(true)
                }, 500) // Set the timeout to match the animation duration
            }
        }, 4000)

        return () => clearInterval(intervalId)
    }, [heroNews?.length, handleSelectHero])

    useEffect(() => {
        if (textRef.current) {
            const naturalWidth = textRef.current.scrollWidth

            if (textRef.current.clientWidth !== naturalWidth) {
                textRef.current.style.width = `${naturalWidth}px`
            }
        }
    }, [heroNews, selectedHeroIndex])

    return (
        <div id="hero" className={classes.heroWrapper}>
            {(heroNews?.[selectedHeroIndex]?.active) && (
                <div className={classes.heroBoxWrapper}>
                    <div className={classes.heroBox} ref={textRef}>
                        <div
                            style={{
                                borderRadius: "5px",
                                background: "#2D2D2D",
                                display: "flex",
                                padding: "5px 10px",
                                alignItems: "flex-start",
                                gap: "10px"
                            }}
                        >
                            <Typography className={classes.heroBoxHeader}>
                                {Localization.new}
                            </Typography>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: isTablet ? "flex-start" : "center",
                                gap: "10px",
                                width: "100%"
                            }}
                            className={fadeIn ? classes.fadeIn : classes.fadeOut}
                            key={fadeInRef.current}
                        >
                            <Typography className={classes.heroBoxTitle} ref={textRef} variant="h1">
                                {heroNews?.[selectedHeroIndex]?.name}
                            </Typography>
                            <Typography className={classes.heroBoxText} variant="h2">
                                {heroNews?.[selectedHeroIndex]?.alt}
                            </Typography>
                            {isTablet
                                ? (
                                    <Button
                                        id="readMore"
                                        className={classnames(
                                            classes.section3Button,
                                            classes.section1Button
                                        )}
                                        component={Link}
                                        to={`/${heroNews?.[selectedHeroIndex].language}/${heroNews?.[selectedHeroIndex]?.href}`}
                                        aria-label="Read more"
                                        disabled={false}
                                    >
                                        {Localization.readMore}
                                        <p style={{
                                            fontSize: 0, width: 0, margin: 0, height: 0
                                        }}
                                        >
                                            {Localization.about}
                                            {" "}
                                            {heroNews?.[selectedHeroIndex]?.name}
                                        </p>
                                    </Button>
                                )
                                : (
                                    <Typography
                                        id="readMore"
                                        to={`/${heroNews?.[selectedHeroIndex].language}/${heroNews?.[selectedHeroIndex]?.href}`}
                                        aria-label="Read more"
                                        disabled={false}
                                        component={Link}
                                        style={{
                                            color: "#2D2D2D",
                                            fontFamily: "Arial",
                                            fontSize: "14px",
                                            fontStyle: "normal",
                                            fontWeight: "700",
                                            lineHeight: "16px", /* 114.286% */
                                            letterSpacing: "0.5px",
                                            textDecoration: "underline 2px #F09057"
                                        }}
                                    >
                                        {Localization.readMore}
                                    </Typography>
                                )}
                        </div>
                        {heroNews.length > 1 && (
                            <div style={{
                                display: "flex", justifyContent: "center", width: "100%", marginTop: 8
                            }}
                            >
                                <div style={{ display: "flex", justifyContent: "center", gap: 10 }}>
                                    {heroNews?.map((item, i) => (
                                        <Button
                                            key={item.name}
                                            onClick={() => handleSelectHero(i)}
                                            className={classes.thumbNailIndicatorCircle}
                                            variant={i === selectedHeroIndex ? "contained" : "outlined"}
                                        />
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}

export default HeroNews

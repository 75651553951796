import { makeStyles } from "@mui/styles";

export default makeStyles(theme => ({
    rotated: {
        transform: "rotate(180deg)"
    },
    iconButton: {
        padding: 4,
        color: "#2d2d2d"
    },
    paper: {
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(3, 4, 3, 3)
    }
}));

import { useLanguage } from "../../../../../lib/hooks/languageHooks"
import { useProducts } from "../../../../../redux/hook/productHooks"
import ActionButtons from "../ActionButtons/ActionButtons"
import { mobileBreakpoint } from "../../../../../lib/helper/viewportHelper"
import { useIsUserAuthorized } from "../../../../../redux/hook/userHooks"
import AddToCartControls from "../AddToCartControls/AddToCartControls"
import { Link } from "react-router-dom"
import { NoPhotographyOutlined } from "@mui/icons-material"
import { styled } from "@mui/styles"
import React, {
    useState
} from "react"

const Base = styled("div")(() => ({
    display: "flex",
    flexWrap: "wrap",
    flex: "0 1 100%",
    justifyContent: "center",
    gap: "5px"
}))

const ButtonWrapper = styled("div")(() => ({
    display: "flex",
    flex: "0 1 100%",
    justifyContent: "center",
    gap: "5px"
}))

const TabButton = styled("button")(({ active }) => ({
    flex: "1",
    padding: "10px 20px",
    background: "#FFFFFF",
    borderRadius: "4px",
    border: active ? "1px #f09057 solid" : "1px transparent solid",
    backgroundColor: active ? "#f09057" : "white",
    cursor: "pointer",
    textTransform: "capitalize",
    fontWeight: active ? 700 : 400
}))

const ProductWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    flex: "0 1 100%",
    flexWrap: "wrap",
    gap: "25px",
    justifyContent: "center",
    [theme.breakpoints.down(1400)]: {
        display: "grid",
        gridTemplateColumns: "420px 420px",
        marginTop: "16px"
    },
    [theme.breakpoints.down(950)]: {
        gridTemplateColumns: "1fr 1fr"
    },
    [theme.breakpoints.down(mobileBreakpoint)]: {
        gridTemplateColumns: "320px"
    }
}))

const ProductCard = styled("div")(() => ({
    display: "flex",
    flex: "0 1 100%",
    flexWrap: "wrap",
    borderRadius: "6px",
    padding: "10px",
    gap: "8px",
    boxShadow: "rgba(45, 45, 45, 0.1) 0px 0px 20px 0px"
}))

const Image = styled("img")(() => ({
    maxWidth: "100%",
    maxHeight: "200px",
    flex: "0 1 100%",
    objectFit: "contain"
}))

const NoImage = styled(NoPhotographyOutlined)(() => ({
    width: "100%",
    height: "100px",
    opacity: 0.2
}))

const ImageWrapper = styled(Link)(() => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: "0 1 100%",
    height: "200px",
    backgroundColor: "#FFFFFF"
}))

const ItemText = styled("div")(() => ({
    flex: "0 1 100%",
    fontFamily: "Arial",
    fontSize: "17px",
    fontWeight: 400,
    lineHeight: "24px",
    letterSpacing: "0.5px"
}))

const CardButtonWrapper = styled("div")(({ authorized }) => ({
    display: "flex",
    flex: "0 1 100%",
    justifyContent: "space-between",
    alignItems: authorized ? "flex-end" : "center"
}))

const ItemLink = styled(Link)(() => ({
    fontFamily: "Arial",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "16px",
    letterSpacing: "0.5px",
    backgroundColor: "#F09057",
    textDecoration: "none",
    padding: "10px 20px",
    color: "#2D2D2D",
    borderRadius: "4px",
    "&:hover": {
        backgroundColor: "#ef7830"
    }
}))

const ModTekSection = ({ modTek }) => {
    const language = useLanguage()
    const [activeId, setActiveId] = useState(modTek.categories[0].id)
    const [productIds, setProductIds] = useState(modTek.categories[0].products)
    const isAuthorized = useIsUserAuthorized()
    // const [categories, setCategories] = useState(modTek.categories)

    const products = useProducts(productIds, "modtek")

    const scrollTop = () => {
        window.scrollTo(0, 0)
    }

    return (
        <Base id="modtekSection">
            <ButtonWrapper>
                {modTek.categories && modTek.categories.map((category, i) => (
                    <TabButton
                        key={category.id}
                        active={category.id === activeId ? 1 : 0}
                        onClick={() => {
                            setActiveId(category.id)
                            setProductIds(category.products) // Update productIds based on active tab
                        }}
                        type="button"
                    >
                        {modTek.title[language][i]}
                    </TabButton>
                ))}
            </ButtonWrapper>
            <ProductWrapper>
                {products.map(product => (
                    <ProductCard key={`modtek-product-${product.id}`}>
                        <ImageWrapper onClick={scrollTop} to={`/${language}/product/${product.originalId}`}><Image src={`${process.env.REACT_APP_IMAGE_URL}w450/${product.originalId}.jpg`} alt="product" /></ImageWrapper>
                        <ItemText>{product.name}</ItemText>
                        <CardButtonWrapper authorized={isAuthorized ? "1" : "0"}>
                            {isAuthorized
                                ? (
                                    <>
                                        <AddToCartControls productId={product.originalId} />
                                        <ActionButtons productId={product.originalId} />
                                    </>
                                )
                                : (
                                    <>
                                        <ActionButtons productId={product.originalId} />
                                        <ItemLink onClick={scrollTop} to={`/${language}/product/${product.originalId}`}>{`Art. ${product.originalId}`}</ItemLink>
                                    </>
                                )}
                        </CardButtonWrapper>
                    </ProductCard>
                ))}
            </ProductWrapper>
        </Base>
    )
}

export default ModTekSection

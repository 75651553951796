import { replaceNameForBrandOther, restoreNameForBrandOther } from "../../lib/helper/brandHelper"
import {
    selectById, selectModtekProductById, selectRelatedProductById
} from "../adapter/productAdapter"
import { createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

// https://stackoverflow.com/questions/66968631/how-can-i-cache-data-that-i-already-requested-and-access-it-from-the-store-using
const fetchProduct = async (id, lang) => {
    const a = await axios.post("/api/ProductInfo/GetProductsByFilter", { productIds: [id], language: lang })
    return a.data
}

const fetchProducts = async (ids, lang, key) => {
    const a = await axios.post("/api/ProductInfo/GetProductsByFilter", { productIds: ids, language: lang })
    return a.data
}

export const fetchModteks = async (ids, lang) => {
    const a = await axios.post("/api/ProductInfo/GetProductsByFilter", { productIds: ids, language: lang })
    return a.data.pagedList.response.list
}

const fetchFilteredProducts = async ({ filters, pagination, lang }) => {
    // Filter out empty search keywords.
    const cleanFilters = Object.entries(filters).reduce((result, [key, value]) => (
        (value.length > 0)
            ? { ...result, [key]: value }
            : result
    ), {})

    // Prevent fetching when filter is empty.
    const isPopulated = Object.values(cleanFilters).some(
        filterValue => filterValue.length > 0
    )
    if (!isPopulated) return null

    // Restore the original name of the "Other models" brand.
    if (cleanFilters.brands) {
        cleanFilters.brands = restoreNameForBrandOther(cleanFilters.brands)
    }

    const parameters = { ...cleanFilters, ...pagination, language: lang }
    const response = await axios.post("/api/ProductInfo/GetProductsByFilter", parameters)

    // Change name of brand "," to "Other models".
    response.data.brands = replaceNameForBrandOther(response.data.brands)

    return response.data
}

export const requestProduct = createAsyncThunk(
    "product/fetchById",
    // Api
    async params => {
        const { id, lang } = params
        return fetchProduct(id, lang)
    },
    {
        condition: (params, { getState }) => {
            const { id, lang } = params
            const existing = selectById(getState(), `${lang}-${id}`)
            return !existing
        }
    }
)

export const requestProducts = createAsyncThunk(
    "product/fetchByIds",
    // Api
    async params => {
        const { ids, lang, key } = params
        return fetchProducts(ids, lang, key)
    },
    {
        condition: (params, { getState }) => {
            const { ids, lang, key } = params
            const state = getState()

            if ((!ids || ids.length === 0 || state.product.loading) && !key) return false

            let shouldRun = false
            let product

            ids.forEach(item => {
                switch (key) {
                    case "modtek":
                        product = selectModtekProductById(state, `${lang}-${item.id}`)
                        break
                    case "related":
                        product = selectRelatedProductById(state, `${lang}-${item.id}`)
                        break
                    default:
                        product = selectById(state, `${lang}-${item.id}`)
                }

                if (!product) shouldRun = true
            })

            return shouldRun
        }
    }
)

export const requestFilteredProducts = createAsyncThunk(
    "product/fetchByFilter",
    async (
        { filters, pagination, lang },
        { getState }
    ) => fetchFilteredProducts({ filters, pagination, lang }, { getState })
    // {
    //     condition: ({ filters, pagination, lang }, { getState }) => {
    //         // Filter out empty search keywords.
    //         let cleanFilters = Object.entries(filters).reduce((result, [key, value]) => {
    //             return (
    //                 (value.length > 0)
    //                     ? { ...result, [key]: value }
    //                     : result
    //             );
    //         }, {});

    //         if (cleanFilters.length > 1) return true;

    //         if (cleanFilters.length < 1) return false;

    //         if (!cleanFilters.productIds) return true;

    //         const existing = cleanFilters.productIds.map(id => selectAll(getState()).some(prod => prod.id === lang + '-' + id)).every(x => x === true);

    //         return !existing;
    //     }
    // }
)

import useStyles from "./styles"
import { formatAmount, formatPrice } from "../../../lib/helper/numberHelper"
import { useLanguage } from "../../../lib/hooks/languageHooks"
import { strings as Localization } from "../../../lib/Localization"
import { nextCartStep } from "../../../redux/cartSlice"
import { useUser, useUserAuthToken } from "../../../redux/hook/userHooks"
import { simulateOrder } from "../../../redux/monitorSlice"
import HeadingRow from "../ProductCardOrder/HeadingRow/HeadingRow"
import ProductCardOrder from "../ProductCardOrder/ProductCardOrder"
import { useProducts } from "../../../redux/hook/productHooks"
import { useClearCartMutation } from "../../../redux/services/cartApi"
import {
    Box, Button, ButtonGroup, CircularProgress, Link as SimpleLink, List, Typography
} from "@mui/material"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import InfoIcon from "@mui/icons-material/Info"
import ReceiptIcon from "@mui/icons-material/Receipt"
import classnames from "classnames"
import React, { useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"

const SummaryButtons = () => {
    const lang = useLanguage()
    const dispatch = useDispatch()

    const classes = useStyles()

    const handleNext = () => {
        dispatch(nextCartStep())
    }

    return (
        <Box className={classes.root}>
            <ButtonGroup>
                <Button
                    startIcon={<ArrowBackIcon />}
                    variant="contained"
                    color="secondary"
                    component={Link}
                    to={`/${lang}`}
                >
                    {Localization.continueShopping}
                </Button>
                <Button
                    endIcon={<ArrowForwardIcon />}
                    id="toCheckout"
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                >
                    {Localization.toCheckout}
                </Button>
            </ButtonGroup>
        </Box>
    )
}

const OrderSummary = () => {
    const dispatch = useDispatch()
    const cartSimulation = useSelector(state => state.monitor.cartSimulation)
    const classes = useStyles()
    const cart = useSelector(state => state.cart)
    const user = useUser()
    const authToken = useUserAuthToken()
    const products = useProducts(cart.items.map(item => item.id))
    const lang = useLanguage()
    const cartItems = useMemo(() => (cart.items.map(item => ({ number: item.id, quantity: item.quantity }))), [cart.items])
    const [clearCart, _result] = useClearCartMutation()

    useEffect(() => {
        const simulate = setTimeout(() => {
            if (cartItems.length > 0) {
                dispatch(simulateOrder(authToken, cartItems))
            }
        }, 500)

        return () => clearTimeout(simulate)
    }, [cartItems, authToken, dispatch])

    const empty = () => {
        clearCart({ authToken })
    }

    if (cart.items.length === 0) {
        return (
            <Box>
                <Box className={classes.headerArea}>
                    <Typography variant="h3">{Localization.yourCart}</Typography>
                    <Typography>{Localization.noArticlesInCart}</Typography>
                </Box>
            </Box>
        )
    }

    if (cartSimulation.length === 0) {
        return (
            <Box>
                <Box className={classes.headerArea}>
                    <Typography variant="h3">{Localization.yourCart}</Typography>
                    <CircularProgress />
                    <Box className={classes.flexRow}>
                        <InfoIcon />
                        <Typography variant="caption">
                            {Localization.experiencingProblemsWithCart}
                            <SimpleLink onClick={empty}>
                                {Localization.clickHere}
                            </SimpleLink>
                            {Localization.toResetYourCart}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        )
    }

    return (
        <Box style={{ flex: "0 0 100%" }}>
            <Box className={classnames(classes.flexRow, classes.marginBottomTheme2)}>
                <Box className={classnames(classes.flexGrow1, classes.flexRow, classes.flexBetween, classes.half)}>
                    <Box className={classes.orderSummary}>
                        <Typography component="p" className={classes.capitalize} variant="caption" style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                            <ReceiptIcon />
                            {Localization.summary}
                        </Typography>
                        <Typography component="p" variant="caption">
                            {Localization.numberOfOrderLines}
                            :
                            {" "}
                            {formatAmount(cart.items.length)}
                        </Typography>
                        <Typography component="p" variant="caption">
                            {Localization.totalQuantity}
                            :
                            {" "}
                            {formatAmount(cart.items.reduce((a, b) => a + b.quantity, 0))}
                        </Typography>
                        <Typography component="p" variant="caption">
                            {Localization.total}
                            {" "}
                            {user?.currency}
                            :
                            {" "}
                            {formatPrice(cartSimulation.reduce((a, b) => a + b.PriceInfo.SuggestedPrice * b.AtQuantity, 0))}
                        </Typography>
                    </Box>
                </Box>
                <Box className={classes.SummaryButtons}>
                    <Box>
                        <SummaryButtons />
                    </Box>
                </Box>
            </Box>
            <Box className={classes.content}>
                <Box className={classes.cartItemsContainer}>
                    <HeadingRow showRestItemsHeading={false} />
                    <List>
                        {[...cart.items].map((item, i) => (
                            <li>
                                <ProductCardOrder
                                    key={i}
                                    id={item.id}
                                    product={products?.find(product => product.id === `${lang}-${item.id}`)}
                                    price={cartSimulation.find(x => x.PartNumber === item.id)?.PriceInfo.SuggestedPrice}
                                    currency={user?.currency}
                                    quantity={item.quantity}
                                    isModifiable
                                    index={i}
                                    text={item.note}
                                />
                            </li>
                        ))}
                    </List>
                </Box>
            </Box>
            <SummaryButtons />
        </Box>
    )
}

export default OrderSummary

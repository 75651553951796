import useStyles from "./styles"
import { useLanguage } from "../../../../lib/hooks/languageHooks"
import { Typography } from "@mui/material"
import { NavLink } from "react-router-dom"
import React from "react"
import classnames from "classnames"

export function GridTitle({
    listView, onClick, product, to, altNameList = []
}) {
    const classes = useStyles()
    const language = useLanguage()
    const altName = altNameList?.find(alt => (
        alt.language_id.toLowerCase() === `${language}1` && product?.originalId === alt?.product_id
    ))
    return (
        listView
            ? (
                <NavLink
                    to={to}
                    onClick={onClick}
                    className={classes.noDisplayLink}
                    end
                >
                    <Typography
                        variant="h1"
                        gutterBottom
                        className={classes.h5Styling}
                    >
                        {(altName) ? altName?.plainText : product?.name}
                    </Typography>
                </NavLink>
            )
            : (
                <Typography variant="h1" gutterBottom className={classes.h5Styling}>
                    {(altName) ? altName?.plainText : product?.name}
                </Typography>
            )
    )
}

export function ListTitle({ name, product, altNameList = [] }) {
    const classes = useStyles()
    const language = useLanguage()
    const altName = altNameList?.find(alt => (
        alt.language_id.toLowerCase() === `${language}1` && product?.originalId === alt?.product_id
    ))
    return (
        <Typography
            variant="h1"
            className={classnames(classes.h5Styling, classes.heading)}
            gutterBottom
        >
            {(altName) ? altName?.plainText : name}
        </Typography>
    )
}

export function ModTekTitle({
    listView, to, onClick, product, altNameList = []
}) {
    const classes = useStyles()
    const language = useLanguage()
    const altName = altNameList?.find(alt => (
        alt.language_id.toLowerCase() === `${language}1` && product?.originalId === alt?.product_id
    ))
    return (
        listView
            ? (
                <NavLink
                    to={to}
                    onClick={onClick}
                    className={classes.noDisplayLink}
                >
                    <Typography
                        gutterBottom
                    >
                        {(altName) ? altName?.plainText : product?.name}
                    </Typography>
                </NavLink>
            )
            : (
                <NavLink
                    to={to}
                    onClick={onClick}
                    className={classes.noDisplayLink}
                >
                    <Typography gutterBottom>
                        {(altName) ? altName?.plainText : product?.name}
                    </Typography>
                </NavLink>
            )
    )
}

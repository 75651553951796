// Need to use the React-specific entry point to import createApi
import { addPrices } from "../priceSlice"
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

export const priceApi = createApi({
    reducerPath: "priceApi",
    baseQuery: fetchBaseQuery({ baseUrl: "/api/ProductInfo/" }),
    endpoints: builder => ({
        getPrices: builder.query({
            query: ({ authToken, ids }) => ({
                url: "GetPrices",
                method: "POST",
                body: {
                    authToken,
                    ids
                }
            }),

            async onQueryStarted({ _authToken, _ids }, { dispatch, queryFulfilled }) {
                try {
                    const { data, meta } = await queryFulfilled

                    if (meta.response.status === 200) {
                        dispatch(addPrices(data))
                    } else {
                        console.warn(`Unexpected status code: ${meta.response.status}`)
                    }
                } catch (err) {
                    if (err?.error?.status) {
                        console.error(`Request failed with status ${err.error.status}`)
                    } else {
                        console.error("Error fetching prices:", err)
                    }
                }
            }
        })
    })
})

export const { useGetPricesQuery } = priceApi

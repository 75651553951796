import { strings as Localization } from "../../../../../lib/Localization"
import { formatPrice } from "../../../../../lib/helper/numberHelper"
import { addToCart } from "../../../../../redux/cartSlice"
import { useLanguage } from "../../../../../lib/hooks/languageHooks"
import { checkMonitorArticles } from "../../../../../lib/helper/userHelper"
import { setSnackbarMessage } from "../../../../../redux/userMessageSlice"
import usePrice from "../../../../../redux/hook/priceHooks"
import React, { useRef, useState } from "react"
import { styled } from "@mui/styles"
import { useDispatch } from "react-redux"
import { TextField } from "@mui/material"

const Base = styled("div")(() => ({
    flex: "0 1 100%",
    display: "flex",
    flexWrap: "wrap",
    gap: "8px"
}))

const Price = styled("div")(() => ({
    flex: "0 1 100%",
    fontSize: "17px",
    fontFamily: "Arial",
    fontWeight: 400,
    color: "#2d2d2d"
}))

const Quantity = styled(TextField)(() => ({
    flex: "0 1 80px",
    backgroundColor: "#FFFFFF"
}))

const AddToCartButton = styled("button")(() => ({
    fontSize: "14px",
    fontFamily: "Arial",
    fontWeight: 700,
    letterSpacing: "0.5px",
    backgroundColor: "#f09057",
    padding: "10px 20px",
    borderRadius: "4px",
    border: 0,
    color: "#2d2d2d",
    cursor: "pointer",
    "&:disabled": {
        opacity: 0.5,
        cursor: "auto"
    }
}))

const AddToCartControls = ({ productId }) => {
    const dispatch = useDispatch()
    const language = useLanguage()
    const price = usePrice(productId)
    const inputRef = useRef(null)

    const [loading, setLoading] = useState(false)
    const [quantity, setQuantity] = useState(0)
    const [error, setError] = useState(false)

    const incorrectQuantity = value => !/^[1-9]\d*$/.test(value)

    const quantityChanged = e => {
        const value = e.target.value

        if (error && !incorrectQuantity(value)) {
            setError(false)
        }

        setQuantity(value)
    }

    const addItemToCart = () => {
        if (incorrectQuantity(quantity)) {
            setError(true)
            return
        }

        setLoading(true)
        checkMonitorArticles(language, [productId], result => {
            if (!result.includes(productId)) {
                dispatch(addToCart(productId, +quantity))
                dispatch(setSnackbarMessage(`${Localization.itemNumber} ${productId} ${Localization.hasBeenAddedToCart}`, "success"))
                setLoading(false)
                setQuantity(0)
                inputRef.current.value = ""
            } else {
                dispatch(setSnackbarMessage(`${Localization.itemNumber} ${productId} ${Localization.couldNotBePlacedInCart}`, "error"))
                setLoading(false)
            }
        })
    }

    return (
        <Base>
            {price && <Price>{`${formatPrice(price.price)} ${price.currency} ${Localization.exVat}`}</Price>}
            <Quantity inputRef={inputRef} error={error} size="small" onChange={quantityChanged} placeholder="0" />
            <AddToCartButton disabled={loading} type="button" onClick={addItemToCart}>{Localization.addToCart}</AddToCartButton>
        </Base>
    )
}

export default AddToCartControls

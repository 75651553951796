import useMaterial from "../../../../../redux/hook/materialHooks"
import { strings as Localization } from "../../../../../lib/Localization"
import { mobileMenuBreakpoint } from "../../../../../lib/helper/viewportHelper"
import { CircularProgress } from "@mui/material"

import React from "react"
import { styled } from "@mui/styles"

export default function Material({ id }) {
    const material = useMaterial(id) // lägg på lang

    if (material === undefined) return <CircularProgress />

    const MaterialRow = styled("div")(() => ({
        display: "flex",
        flex: "0 1 100%",
        flexWrap: "wrap",
        "&:nth-child(even)": {
            flexDirection: "row-reverse"
        }
    }))

    const MaterialImageWrapper = styled("div")(({ theme }) => ({
        display: "flex",
        alignItems: "center",
        flex: "0 1 50%",
        [theme.breakpoints.down(mobileMenuBreakpoint)]: {
            flex: "0 1 100%",
            justifyContent: "center"
        }
    }))

    const MaterialText = styled("div")(({ theme }) => ({
        flex: "0 1 50%",
        [theme.breakpoints.down(mobileMenuBreakpoint)]: {
            flex: "0 1 100%"
        }
    }))

    const MaterialImage = styled("img")(() => ({
        maxWidth: "100%"
    }))

    const Title = styled("div")(() => ({
        fontWeight: 700,
        width: "100%",
        margin: "8px 0"
    }))

    return (
        <MaterialRow>
            <MaterialImageWrapper>
                <MaterialImage
                    loading="lazy"
                    src={`${process.env.REACT_APP_IMAGE_URL}w450/${material.originalId}.jpg`}
                    alt={`Brodit ${Localization.product}-${material.originalId}`}
                />
            </MaterialImageWrapper>
            <MaterialText>
                <Title>{material.name}</Title>
                <div>{material.information}</div>
            </MaterialText>
        </MaterialRow>
    )
}

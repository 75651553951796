import { useUserAuthToken } from "../../../redux/hook/userHooks"
import { deleteFavoriteList, setFavoriteProducts } from "../../../redux/favoriteSlice"
import { fetchModteks } from "../../../redux/thunk/productThunk"
import ProductViewToggleButton from "../../Products/ProductViewToggleButton/ProductViewToggleButton"
// eslint-disable-next-line import/no-cycle
import Products from "../../Products/Products"
import CheckIcon from "../../../assets/check.png"
import ClearIcon from "../../../assets/close.png"
import Trashcan from "../../../assets/delete.png"
import PenIcon from "../../../assets/pen.png"
import { strings as Localization } from "../../../lib/Localization"
import React, { useState, useEffect } from "react"

import { useDispatch } from "react-redux"
import axios from "axios"

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Autocomplete, Box, Chip, TextField, Tooltip, Typography
} from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"

function Userlist({
    userLists, list = [], language, classes, theme, productsArray, index
}) {
    const [data, setData] = useState(null)
    const [name, setName] = useState(list[0]?.listName)
    const [isEditing, setIsEditing] = useState(false)
    const [tempName, setTempName] = useState("")
    const dispatch = useDispatch()
    const authToken = useUserAuthToken()
    const idArray = list?.map(x => x.productId)
    const [selectedItems, setSelectedItems] = useState(idArray)
    const productList = productsArray
    const [expandedAccordion, setExpandedAccordion] = useState((userLists.length === 1 && index === 0) ? 0 : null)

    useEffect(() => {
        setExpandedAccordion((userLists.length === 1 && index === 0) ? 0 : null)
    }, [userLists])

    const handleAccordionChange = i => {
        setExpandedAccordion(expandedAccordion === i ? null : i)
    }

    const updateFavorites = async ({ newName, products, oldName }) => {
        const a = await axios.post(`/api/favorite/UpdateFavoriteListName?authToken=${authToken}`, { newName, products, oldName })
        dispatch(setFavoriteProducts(a.data.response))
        setIsEditing(false)
    }

    const deleteFavortiteList = async () => {
        dispatch(deleteFavoriteList(name))
        await axios.post(`/api/favorite/DeleteFavoriteListByName?authToken=${authToken}`, { name })
    }

    const handleEditToggle = event => {
        event.stopPropagation()
        setIsEditing(true)
        setTempName(name)
    }

    const handleNameChange = event => {
        event.stopPropagation()
        setTempName(event.target.value)
    }

    const handleSave = event => {
        event.stopPropagation()
        setName(tempName)
        setIsEditing(false)
        const newObject = { newName: tempName, products: selectedItems, oldName: list[0]?.listName }
        updateFavorites(newObject)
    }

    const handleDelete = () => {
        deleteFavortiteList()
        setTempName("")
        setSelectedItems(idArray)
    }

    const handleCancel = event => {
        event.stopPropagation()
        setTempName(name)
        setIsEditing(false)
        setTempName("")
        setSelectedItems(idArray)
    }

    const handleKeyDown = event => {
        event.stopPropagation()
        if (event.key === "Enter") {
            handleEditToggle()
        }
    }

    const handleKeyDownDelete = event => {
        event.stopPropagation()
        if (event.key === "Enter") {
            deleteFavortiteList()
        }
    }

    const handleSelect = (event, value) => {
        event.stopPropagation()
        const selectedOptions = value
        setSelectedItems(selectedOptions)
    }

    useEffect(() => {
        let isMounted = true

        const fetchData = async () => {
            try {
                const d = await fetchModteks(idArray, language)
                const processedData = d.map(x => ({
                    ...x,
                    originalId: x.id,
                    id: `${language}-${x.id}`
                }))

                if (isMounted) {
                    setData(processedData.sort((a, b) => a.originalId.localeCompare(b.originalId)))
                }
            } catch (error) {
                console.error("Error fetching data:", error)
            }
        }

        fetchData()
        setName(list[0]?.listName)
        setSelectedItems(idArray)

        return () => {
            isMounted = false
        }
    }, [list, language])

    return (
        <Accordion
            aria-label="Products"
            key={list[0]?.listName}
            id={list[0]?.listName}
            expanded={expandedAccordion === index}
            onChange={() => handleAccordionChange(index)}
        >
            <AccordionSummary
                {...{
                    id: `simple-tab-${index}`,
                    "aria-controls": `simple-tabpanel-${index}`
                }}
                style={{ witdh: "100%" }}
                expandIcon={(
                    <ExpandMoreIcon style={{ width: 32 }} />
                )}
            >
                <Box style={{
                    margin: `0 ${theme.spacing(3)}px`,
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%"
                }}
                >
                    <Box style={{ display: "flex", gap: 8, flexWrap: "wrap" }}>
                        {isEditing ? (
                            <TextField
                                id="name"
                                type="filled"
                                value={tempName}
                                label={`${Localization.newName}`}
                                variant="standard"
                                placeholder={list[0]?.listName}
                                onClick={e => e.stopPropagation()}
                                onChange={handleNameChange}
                                sx={{
                                    "& .MuiInput-input": { padding: "0px!important", height: "auto!important" },
                                    width: "96px",
                                    "& .MuiInput-root::before": {
                                        borderBottom: "none"
                                    }
                                }}
                            />
                        ) : (
                            <Typography style={{ fontSize: "27px", display: "flex", alignItems: "center" }}>
                                {name}
                            </Typography>
                        )}
                        {isEditing
                            && (
                                <>
                                    <Autocomplete
                                        id="favoriteSelector"
                                        onClick={e => e.stopPropagation()}
                                        onChange={handleSelect}
                                        multiple
                                        options={productList}
                                        className={classes.select}
                                        variant="standard"
                                        value={selectedItems}
                                        disableCloseOnSelect
                                        clearText={Localization.clear}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                onClick={e => e.stopPropagation()}
                                                variant="standard"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    disableUnderline: true
                                                }}
                                                label={Localization.selectProducts}
                                                placeholder={Localization.productId}
                                                sx={{
                                                    "& .MuiInputBase-root": {
                                                        height: "24px"
                                                    },
                                                    "& .MuiInputBase-root-MuiInput-root": {
                                                        marginTop: 0
                                                    },
                                                    "& .MuiInput-root .MuiInput-input": {
                                                        padding: "0px"
                                                    }
                                                }}
                                            />
                                        )}
                                        renderTags={value => (value.length > 0 ? (
                                            <Chip onClick={e => e.stopPropagation()} label={`${value.length}`} style={{ height: 24 }} />
                                        ) : null)}
                                    />
                                    <div style={{ display: "flex" }}>
                                        <Tooltip title={Localization.save}>
                                            <Box
                                                tabIndex="0"
                                                role="button"
                                                onKeyDown={e => e.key === "Enter" && handleSave}
                                                onClick={handleSave}
                                                style={{ display: "flex", alignItems: "center" }}
                                            >
                                                <img src={CheckIcon} alt={Localization.save} style={{ width: "24px", height: "24px" }} />
                                            </Box>
                                        </Tooltip>
                                        <Tooltip title={Localization.cancel}>
                                            <Box
                                                tabIndex="0"
                                                role="button"
                                                onKeyDown={e => e.key === "Enter" && handleCancel}
                                                onClick={handleCancel}
                                                style={{ display: "flex", alignItems: "center" }}
                                            >
                                                <img src={ClearIcon} alt={Localization.cancel} style={{ width: "24px", height: "24px" }} />
                                            </Box>
                                        </Tooltip>
                                    </div>
                                </>
                            )}
                        {(!isEditing && expandedAccordion === index) && (
                            <Tooltip title={Localization.editList}>
                                <div role="button" onClick={handleEditToggle} onKeyDown={handleKeyDown} tabIndex="0" style={{ cursor: "pointer", display: "flex", alignItems: "center" }}>
                                    <img
                                        src={PenIcon}
                                        style={{ marginLeft: "8px", height: "16px", width: "16px" }}
                                        alt="edit name"
                                    />
                                </div>
                            </Tooltip>
                        )}
                    </Box>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Tooltip title={Localization.deleteList}>
                            <div role="button" onClick={handleDelete} aria-label={Localization.deleteListButton} onKeyDown={handleKeyDownDelete} tabIndex="0" style={{ cursor: "pointer", display: "flex" }}>
                                <img src={Trashcan} alt="delete button" />
                            </div>
                        </Tooltip>
                        <ProductViewToggleButton />
                    </div>
                </Box>
            </AccordionSummary>
            <AccordionDetails
                index={index}
            >
                <Products
                    products={data ?? []}
                />
            </AccordionDetails>
        </Accordion>
    )
}

export default Userlist

/* eslint-disable no-nested-ternary */
// Fyll på med alla saker från adaptern..

import { createEntityAdapter } from "@reduxjs/toolkit"

export const productAdapter = createEntityAdapter({
    selectId: product => product.id,
    sortComparer: (a, b) => (a.sortOrder > b.sortOrder ? 1 : ((b.sortOrder > a.sortOrder) ? -1 : 0))
})

export const modtekProductSelectors = productAdapter.getSelectors(
    state => state.product.modtekProducts
)

export const relatedProductSelectors = productAdapter.getSelectors(
    state => state.product.relatedProducts
)

export const { selectAll: selectAllModtekProducts, selectById: selectModtekProductById } = modtekProductSelectors;

export const { selectAll: selectAllRelatedProducts, selectById: selectRelatedProductById } = relatedProductSelectors;

export const productSelectors = productAdapter.getSelectors(state => state.product)

export const { selectById, selectAll } = productSelectors

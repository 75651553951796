import { makeStyles } from "@mui/styles"

export default makeStyles(theme => ({
    indexRoot: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        backgroundColor: theme.palette.background.paper
    },
    grayText: {
        color: "#666"
    },
    blackText: {
        color: "#2D2D2D",
        marginTop: "8px"
    },
    listRoot: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        backgroundColor: theme.palette.background.paper
    },
    cardRoot: {
        display: "flex",
        flexWrap: "wrap",
        backgroundColor: theme.palette.background.paper,
        flex: "0 0 calc(50% - 16px)",
        justifyContent: "center",
        boxSizing: "border-box",
        margin: "8px",
        alignContent: "flex-start"
    },
    root: {
        marginLeft: "10px",
        marginRight: "10px"
    },
    image: {
        height: 200,
        width: 300
    },
    card: {
        height: "auto",
        width: 300,
        border: "none",
        boxShadow: "none"
    }
}))

import { moveWordsToTopComparer } from "../../../../../lib/helper/commonHelper"
import { compatibleModelText } from "../../../../../lib/helper/modelVersionsHelper"
import { useSearchQuery } from "../../../../../redux/hook/searchHooks"
import theme from "../../../../../theme"
import { strings as Localization } from "../../../../../lib/Localization"
import { styled } from "@mui/styles"
import React, { useState } from "react"
import { useMediaQuery } from "@mui/material"

const CompabilityTitle = styled("div")(() => ({
    flex: "0 0 100%",
    fontFamily: "Arial",
    fontSize: "17px",
    fontWeight: 700,
    lineHeight: "24px",
    letterSpacing: "0.5px",
    color: "#2D2D2D",
    marginTop: "20px"
}))

const CompatibleModelsListEl = styled("div")(({ open }) => ({
    display: "flex",
    flexWrap: "wrap",
    flex: "0 1 100%",
    marginTop: "10px",
    overflowY: open ? "scroll" : "unset",
    maxHeight: "300px"
}))

const CompatibleItem = styled("div")(() => ({
    flex: "0 1 100%",
    fontFamily: "Arial",
    fontSize: "17px",
    fontWeight: 400,
    lineHeight: "24px",
    letterSpacing: "0.5px",
    color: "#2D2D2D"
}))

const CompatibleModelsButton = styled("button")(() => ({
    backgroundColor: "transparent",
    border: 0,
    padding: 0,
    fontFamily: "Arial",
    fontSize: "17px",
    fontWeight: 400,
    lineHeight: "24px",
    letterSpacing: "0.5px",
    color: "#EA600F",
    cursor: "pointer"
}))

const StyledHr = styled("hr")(() => ({
    flex: "0 1 100%",
    borderTop: "1px solid rgba(45, 45, 45, 0.3)",
    marginTop: "30px",
    marginBottom: 0
}))

const CompatibleModelsList = ({ models }) => {
    const allSearchQueries = useSearchQuery()
    const isDesktop = useMediaQuery(theme.breakpoints.up(1400))

    const brand = allSearchQueries.brands?.[0] ?? ""
    const model = allSearchQueries.models?.[0] ?? ""
    const year = allSearchQueries.years?.[0] ?? ""
    const sortedCompatibleModels = [...models].sort((a, b) => moveWordsToTopComparer(
        compatibleModelText(a),
        compatibleModelText(b),
        [brand, model, year]
    )).filter(x => x.brand !== ",")

    const [compatibleModelsOpen, setCompatibleModelsOpen] = useState(false)

    return sortedCompatibleModels.length > 0 ? (
        <>
            {isDesktop && <StyledHr />}
            <CompabilityTitle>{`${Localization.fitsFor}:`}</CompabilityTitle>
            <CompatibleModelsListEl open={compatibleModelsOpen ? 1 : 0}>
                {models && sortedCompatibleModels
                    .slice(0, compatibleModelsOpen ? sortedCompatibleModels.length : 3)
                    .map(
                        (comp, i) => <CompatibleItem key={`comp-${i}`}>{compatibleModelText(comp)}</CompatibleItem>
                    )}
            </CompatibleModelsListEl>
            {sortedCompatibleModels.length > 3
                && <CompatibleModelsButton type="button" onClick={() => setCompatibleModelsOpen(!compatibleModelsOpen)}>{compatibleModelsOpen ? "Se färre modeller." : "Se fler modeller."}</CompatibleModelsButton>}
        </>
    ) : null
}

export default CompatibleModelsList

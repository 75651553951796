import ShowMore from "./showMore"
import useStyles from "../styles"
import { strings as Localization } from "../../../../lib/Localization"
import {
    Box, Button, Checkbox, Grid, List, ListItem, ListItemIcon,
    ListItemText, Typography, useTheme
} from "@mui/material"
import React, { useState } from "react"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"

const CheckboxList = ({
    allItems, selectedItems, heading, add, remove, showAll
}) => {
    const classes = useStyles()
    const theme = useTheme()
    const [open, setOpen] = useState(false)

    const checkIfSelected = item => selectedItems.some(
        selected => (
            selected.id === item.id
            || selected === item.id
            || selected.name === item.name
            || selected === item.name
        )
    )

    const handleToggle = item => () => {
        const exists = checkIfSelected(item)
        if (!exists) {
            add(item)
        } else {
            remove(item)
        }
    }

    const toggleShowMore = () => {
        setOpen(prev => !prev)
    }
    return (
        <div style={{ marginBottom: theme.spacing(3) }}>
            <Grid container direction="column">
                <Grid item>
                    <Box style={{ display: "flex" }}>
                        <Typography variant="h6" className={classes.capitalized}>{heading}</Typography>
                        {!showAll
                            && (
                                <Button
                                    variant="text"
                                    aria-label={Localization.showMore}
                                    onClick={toggleShowMore}
                                    size="small"
                                    classes={{
                                        root: classes.buttonRoot
                                    }}
                                    className={classes.button}
                                >
                                    {
                                        open ? <ExpandLessIcon /> : <ExpandMoreIcon />
                                    }
                                </Button>
                            )}
                    </Box>
                </Grid>
                <Grid item>
                    <List className={classes.listRoot}>
                        <ShowMore showAll={showAll} open={open}>
                            {allItems.map(item => {
                                const labelId = `checkbox-list-label-${item.id}`

                                return (
                                    <ListItem
                                        key={item.id}
                                        role={undefined}
                                        dense
                                        ButtonBase
                                        onClick={handleToggle(item)}
                                        classes={{ dense: classes.dense }}
                                    >
                                        <ListItemIcon style={{ minWidth: 0 }}>
                                            <Checkbox
                                                edge="start"
                                                checked={checkIfSelected(item)}
                                                tabIndex={-1}
                                                disableRipple
                                                classes={{
                                                    root: classes.icon,
                                                    checked: classes.checked
                                                }}
                                                inputProps={{ "aria-labelledby": labelId, "aria-label": "checkbox" }}
                                            />
                                        </ListItemIcon>
                                        <ListItemText id={labelId} primary={item.name} />
                                    </ListItem>
                                )
                            })}
                        </ShowMore>
                    </List>
                </Grid>
            </Grid>
        </div>
    )
}

export default CheckboxList

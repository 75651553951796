import { strings as Localization } from "../../../../lib/Localization"
import { useProduct } from "../../../../redux/hook/productHooks"
import Text from "../components/Text/index"
import useStyles from "../styles"
import React from "react"
import {
    Box, Table, TableBody, TableCell, TableRow, Typography
} from "@mui/material"

export default function Specification({ id }) {
    const product = useProduct(id)
    const classes = useStyles()

    return (
        <Box className={classes.column} id="tabSpecification">
            <Typography variant="h5" gutterBottom style={{ marginTop: "8px", color: "#2d2d2d" }}>{Localization.productInformation}</Typography>
            <Table className={classes.tableRoot}>
                <TableBody>
                    <TableRow>
                        <TableCell className={classes.grayText}>{Localization.itemNumber}</TableCell>
                        <TableCell className={classes.grayText}>{product.originalId}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className={classes.grayText}>{Localization.eanNumber}</TableCell>
                        <TableCell className={classes.grayText}>{product.eanNumber}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            {product.properties.length > 0
                ? (
                    <Box>
                        <Typography variant="h5" gutterBottom style={{ color: "#2d2d2d" }}>{Localization.properties}</Typography>
                        <Table className={classes.tableRoot}>
                            <TableBody>
                                {product.properties.map(item => (
                                    <TableRow key={item.displayName}>
                                        <TableCell className={classes.grayText}>{item.displayName}</TableCell>
                                        <TableCell className={classes.grayText}>{item.value}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Box>
                )
                : null}
            {product.chargingSpecification
                && (
                    <>
                        <Typography variant="h5" gutterBottom className={classes.grayText}>{Localization.chargingSpecification}</Typography>
                        <Text className={classes.grayText} text={product.chargingSpecification} />
                    </>
                )}
        </Box>
    )
}

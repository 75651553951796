import { filterFaultyValues } from "../lib/helper/searchHelper"
import { createSlice } from "@reduxjs/toolkit"

const searchSlice = createSlice({
    name: "search",
    reducers: {
        /**
         * Creates a new search query entry with the name of "type" containing
         * the value of "value".
         * @param {*} state The current state (using "Immer").
         * @param {object} payload An object containing two keys { type, value }.
         * payload.type {string} The name of the entry to create/override.
         * payload.value {object} The value of the search query entry.
         * Note: The payload.value object must follow the standard, that for each
         * key the value must be represented as an array, for example:
         * { customKeyword: ["value1", "value2", ...], anotherCustomKeyword: ["another value"], thirdCustomKeyword: [], ...}
         * Keys with faulty values, for example empty arrays and falsy values
         * will not be added.
         */
        setSearchQuery: (state, { payload }) => {
            const { type, value, clearFilter } = payload
            if (clearFilter) state.searchQueries = {}
            state.searchQueries[type] = filterFaultyValues(value)
        },

        /**
         * Overrides a part of a search query entry with the name of "type" and
         * spreads in the value of "value".
         * @param {*} state The current state (using "Immer").
         * @param {object} payload An object containing two keys { type, value }.
         * payload.type {string} The name of the entry to create/update.
         * payload.value {object} The value you want to spread into the
         * search querys existing value.
         * Note: The payload.value object must follow the standard, that for each
         * key the value must be represented as an array, for example:
         * { customKeyword: ["value1", "value2", ...], anotherCustomKeyword: ["another value"], thirdCustomKeyword: [], ...}
         * Keys with faulty values, for example empty arrays and falsy values
         * will not be added.
         */
        updateSearchQuery: (state, { payload }) => {
            const { type, value } = payload

            const newValue = {
                ...state.searchQueries[type],
                ...value
            }

            state.searchQueries[type] = filterFaultyValues(newValue)
        },

        /**
         * Removes all filters in the search query.
         * @param {*} state The current state (using "Immer").
         * @param {object} payload (Optional) An object containing the key { exclude }.
         * payload.exclude {Array<string>} The search query keys specified in
         * this list will not be removed.
         */
        clearSearchQuery: (state, { payload }) => {
            if (payload?.exclude) {
                // Removes all keys except the excluded keys
                const excludedSearchQueries = Object.entries(state.searchQueries)
                    .reduce(
                        (prev, [key, value]) => (
                            payload.exclude.includes(key)
                                ? { ...prev, [key]: value }
                                : prev
                        ),
                        {}
                    )

                state.searchQueries = excludedSearchQueries
            } else {
                state.searchQueries = {}
            }
        }
    },

    initialState: {
        /**
         * Search query entry inside the searchQueries object should always follow
         * the standard of "componentNameResponsibleForThisQuery: { customKeyword: [], anotherCustomKeyword: [], ...}
         */
        searchQueries: {
            // Example structure:
            // vehicleSearchbar: { brands: [], models: [], years: [] },
            // deviceSearchbar: { brands: [], models: [] },
            // filterProducts: { brands: [], models: [], years: [], collections: [] },
        }
    }
})

export const { setSearchQuery, updateSearchQuery, clearSearchQuery } = searchSlice.actions
export default searchSlice.reducer

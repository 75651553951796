import useStyles from "../styles"
import { Box, Typography } from "@mui/material"
import classnames from "classnames"
import React from "react"

const Headers = ({ headerList }) => {
    const classes = useStyles()

    return (
        <Box className={classnames(classes.flexRow, classes.headerRoot)}>
            {headerList.map((title, index) => (
                <Box className={classes.flexItem} key={index}>
                    <Typography variant="h2" className={classnames(classes.gray, classes.capitalized, classes.h6Styling)}>
                        {title}
                    </Typography>
                </Box>
            ))}
        </Box>
    )
}

export default Headers

import ExpandIcon from "./assets/expand.svg"
import {
    Accordion,
    AccordionSummary,
    AccordionDetails
} from "@mui/material"
import { styled } from "@mui/styles"
import React, { useRef } from "react"

const ProductAccordion = (
    {
        title = "",
        text = "",
        defaultExpanded = false,
        children = null
    }
) => {
    const accordionRef = useRef(null)

    const Wrapper = styled("div")(() => ({
        display: "flex",
        flex: "0 1 100%",
        justifyContent: "space-between",
        paddingTop: "20px"
    }))

    const StyledAccordion = styled(Accordion)(() => ({
        flex: "0 1 100%",
        fontFamily: "Arial",
        fontSize: "17px",
        fontWeight: 400,
        lineHeight: "24px",
        letterSpacing: "0.5px",
        boxShadow: "unset",
        "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
            transform: "rotate(45deg)"
        }
    }))

    const StyledAccordionSummary = styled(AccordionSummary)(() => ({
        backgroundColor: "#F8F8F8"
    }))

    const Details = styled(AccordionDetails)(() => ({
        whiteSpace: "pre-line",
        "& span": {
            fontSize: "1rem !important",
            fontFamily: "Arial !important"
        }
    }))

    return text !== "" || children ? (
        <Wrapper>
            <StyledAccordion
                defaultExpanded={defaultExpanded}
                disableGutters
                ref={accordionRef}
            >
                <StyledAccordionSummary expandIcon={<img src={ExpandIcon} alt="expand" />}>
                    {title}
                </StyledAccordionSummary>
                <Details>
                    {text !== "" && <div dangerouslySetInnerHTML={{ __html: text }} />}
                    {children}
                </Details>
            </StyledAccordion>
        </Wrapper>
    ) : null
}

export default ProductAccordion

import useStyles from "./Holder.styles"
import { useLanguage } from "../../../lib/hooks/languageHooks"
import React from "react"
import {
    Box, Typography
} from "@mui/material"
import { Link } from "react-router-dom"

const HolderElement = ({
    id, src, isDesktop, alt, title, text, link
}) => {
    const classes = useStyles()
    const lang = useLanguage()

    return (
        <Box className={classes.outerWrapper}>
            <Box id={id} className={classes.holderWrapper}>
                <img
                    src={src}
                    alt={alt}
                    style={{
                        width: isDesktop ? "446px" : "100%",
                        height: isDesktop ? "340px" : "auto",
                        aspectRatio: "446/340",
                        objectFit: "cover"
                    }}
                />
                <Box className={classes.holderTextBox}>
                    <div>
                        <Typography className={classes.blackH3Roboto} variant="h3">
                            {title}
                        </Typography>
                        <Typography className={classes.blackArialText}>
                            {text}
                        </Typography>
                    </div>
                    <Link className={classes.link} to={`/${lang}/categories#${link.hash}`}>{link.title}</Link>
                </Box>
            </Box>

        </Box>
    )
}

export default HolderElement

import { strings as Localization } from "../../lib/Localization"
import { addToFavoriteProducts, removeFromFavoriteProducts } from "../../redux/favoriteSlice"
import { useIsUserAuthorized, useUser, useUserAuthToken } from "../../redux/hook/userHooks"
import React, { useMemo, useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import {
    Autocomplete, Box, Button, Dialog, IconButton, Popper, TextField, Tooltip
} from "@mui/material"
import FavoriteIcon from "@mui/icons-material/Favorite"
import axios from "axios"
import { styled } from "@mui/styles"

const CustomPopper = styled(Popper)({
    "&.MuiAutocomplete-popper": {
        width: "200px !important",
        left: "60px !important"
    },
    "& .MuiAutocomplete-listbox": {
        width: "200px"
    }
})

function FavoriteButton({ productId }) {
    const dispatch = useDispatch()
    const favorites = useSelector(state => state.favorite.favoriteProducts)
    const user = useUser()
    const userLists = useMemo(
        () => [...[...Array.from(
            new Set(favorites.filter(x => x?.listName !== null && user.user.customerNumber === x.customerNumber).map(item => item?.listName))
        ).sort((a, b) => a.localeCompare(b))], Localization.yourFavorites],
        [favorites]
    )

    const [open, setOpen] = useState(false)
    const isAuthorized = useIsUserAuthorized()
    const isFavorited = useMemo(
        () => favorites.some(product => product.productId === productId && user.user.customerNumber === product.customerNumber),
        [favorites, productId]
    )
    const authToken = useUserAuthToken()
    const [selectedItems, setSelectedItems] = useState([Localization.yourFavorites])

    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)
    const [options, setOptions] = useState(userLists)

    useEffect(() => {
        setOptions(userLists)
    }, [userLists])

    const handleChange = (_event, newValue, reason) => {
        if (reason === "select-option" || reason === "create-option") {
            const newOptions = Array.isArray(newValue)
                ? [...new Set([...options, ...newValue])]
                : [...new Set([...options, newValue])]
            setOptions(newOptions)
        }

        setSelectedItems(newValue)
    }

    const handleSave = async () => {
        try {
            const updatedItems = selectedItems.map(item => (item === Localization.yourFavorites ? null : item))

            const payload = {
                productId,
                listName: updatedItems
            }

            const response = await axios.post(`/api/favorite/CreateFavorite?authToken=${authToken}`, payload)
            response.data.response.map(x => dispatch(addToFavoriteProducts(x)))

            handleClose()
        } catch (error) {
            console.error("Error during save operation:", error)
        }
    }

    const toggleFavorite = async () => {
        if (isFavorited) {
            dispatch(removeFromFavoriteProducts(productId))
            axios.post(`/api/favorite/DeleteFavorite?authToken=${authToken}`, { productId: productId })
            return
        }
        handleOpen()
    }

    return (
        isAuthorized
        && (
            <Tooltip title={isFavorited ? Localization.removeFromFavorites : Localization.addToFavorites}>

                <div id="heartIcon">
                    <IconButton size="small" aria-label={Localization.heartIcon} onClick={toggleFavorite} style={{ color: isFavorited ? "#f09057" : "#2d2d2d" }}>
                        <FavoriteIcon />
                    </IconButton>
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        maxWidth="xs"
                        fullWidth
                        style={{
                            position: "fixed"
                        }}
                    >
                        <Box p={2}>
                            <Autocomplete
                                freeSolo
                                autoSelect
                                multiple
                                options={options}
                                value={selectedItems}
                                onChange={handleChange}
                                label={Localization.selectLists}
                                renderInput={params => (
                                    <TextField {...params} label={Localization.selectLists} variant="standard" />
                                )}
                                PopperComponent={CustomPopper}
                            />
                            <Button onClick={handleSave} id="save" variant="contained" style={{ marginTop: "10px", marginRight: "10px" }}>
                                {Localization.save}
                            </Button>
                            <Button onClick={handleClose} id="close" variant="contained" style={{ marginTop: "10px" }}>
                                {Localization.close}
                            </Button>
                        </Box>
                    </Dialog>
                </div>
            </Tooltip>
        )
    )
}

export default FavoriteButton

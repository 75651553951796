import { emptyCart, setCartItems, setShouldSyncCart } from "../cartSlice"
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { batch } from "react-redux"

export const cartApi = createApi({
    reducerPath: "cartApi",
    baseQuery: fetchBaseQuery({ baseUrl: "/api/cart/" }),
    endpoints: builder => ({

        getCart: builder.query({
            query: ({ authToken }) => `GetFromAuthToken?authToken=${authToken}`,
            // transformResponse: (response) => response?.response,

            async onQueryStarted({ _authToken }, { dispatch, queryFulfilled }) {
                // Request has started.
                try {
                    const { data: cartFromServer } = await queryFulfilled
                    // Request succeeded.
                    // Batch prevents rerendering for each dispatch and
                    // instead only rerenders once.
                    batch(() => {
                        dispatch(setCartItems(
                            cartFromServer
                        ))
                        dispatch(setShouldSyncCart(false))
                    })
                } catch (err) {
                    // Request returned and error.
                }
            }
        }),

        updateCart: builder.mutation({
            query: ({ authToken, ...parameters }) => ({
                url: `UpdateFromAuthToken?authToken=${authToken}`,
                method: "POST",
                body: {
                    ...parameters
                }
            })
            // transformResponse: (response) => response?.response,
        }),
        clearCart: builder.mutation({
            query: ({ authToken }) => ({
                url: `ClearCart?authToken=${authToken}`,
                method: "POST",
                body: {}
            }),
            // You can add cache manipulation here if necessary, but avoid hooks
            async onQueryStarted({ authToken }, { dispatch }) {
                try {
                    batch(() => {
                        dispatch(emptyCart())
                        dispatch(setShouldSyncCart(false))
                    })
                } catch (err) {
                    console.error("Error clearig cart:", err)
                }
            }
        })
    })
})

export const { useGetCartQuery, useUpdateCartMutation, useClearCartMutation } = cartApi

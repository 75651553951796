import useStyles from "./styles"
// eslint-disable-next-line import/no-cycle
import Userlist from "./Userlist"
import { strings as Localization } from "../../../lib/Localization"
import { useFilteredProducts, useProductsLoading } from "../../../redux/hook/productHooks"
import Products from "../../Products/Products"
import ProductViewToggleButton from "../../Products/ProductViewToggleButton/ProductViewToggleButton"
import { useLanguage } from "../../../lib/hooks/languageHooks"
import { useUser, useUserAuthToken } from "../../../redux/hook/userHooks"
import CheckIcon from "../../../assets/check.png"
import ClearIcon from "../../../assets/close.png"
import { setFavoriteProducts } from "../../../redux/favoriteSlice"
import { mobileBreakpoint, tabletBreakpoint } from "../../../lib/helper/viewportHelper"
import {
    Alert,
    Autocomplete,
    Box, Button, Chip, Divider, Snackbar, TextField, Tooltip, Typography, useMediaQuery, useTheme
} from "@mui/material"
import classNames from "classnames"
import React, { useMemo, useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import axios from "axios"

function FavoriteProductsPage() {
    const classes = useStyles()
    const isMobile = useMediaQuery(`(max-width:${mobileBreakpoint}px)`)
    const isTablet = useMediaQuery(`(max-width:${tabletBreakpoint}px)`)
    const favoriteProducts = useSelector(state => state.favorite.favoriteProducts)
    const user = useUser()
    const [userLists, setUserLists] = useState([])

    useEffect(() => {
        const updatedLists = Object.values(
            favoriteProducts
                .filter(
                    x => x.listName !== null && user.user.customerNumber === x.customerNumber
                )
                .reduce((result, item) => {
                    if (!result[item.listName]) {
                        result[item.listName] = []
                    }
                    result[item.listName].push(item)
                    return result
                }, {})
        ).sort((a, b) => a[0].listName.localeCompare(b[0].listName))
        setUserLists(updatedLists)
    }, [favoriteProducts])

    const idArray = [...Array.from(
        new Set(favoriteProducts
            .filter(x => x.listName === null && user.user.customerNumber === x.customerNumber)
            .map(x => x.productId))
    )]

    const filter = useMemo(() => ({ productIds: idArray }), [favoriteProducts])
    const pagination = useMemo(() => ({ offset: 0, limit: -1 }), [])
    const isLoading = useProductsLoading()
    const falseProducts = useFilteredProducts(filter, pagination)
    const products = useMemo(() => [...falseProducts]?.sort((a, b) => a?.originalId.localeCompare(b?.originalId)), [falseProducts])
    const userFavorites = [...new Set(favoriteProducts.filter(x => user.user.customerNumber === x.customerNumber).map(x => x.productId).flat())]

    const missingProducts = useMemo(() => (
        idArray?.filter(id => !products?.some(product => product.originalId === id))
    ), [favoriteProducts, products])
    const theme = useTheme()
    const language = useLanguage()
    const authToken = useUserAuthToken()

    const [showNotInList, setShowNotInList] = useState(false)
    const notInList = products?.filter(item => ![...new Set(userLists?.map(x => x.map(y => y.productId)).flat())].includes(item.originalId))
    const [isEditing, setIsEditing] = useState(false)
    const [tempName, setTempName] = useState("")
    const [selectedItems, setSelectedItems] = useState([])
    const [errorOpen, setErrorOpen] = useState(false)
    const dispatch = useDispatch()

    const handleNameChange = event => {
        setTempName(event.target.value)
    }

    const handleSave = async () => {
        if (selectedItems.length > 0 && tempName.length > 0) {
            const a = await axios.post(`/api/favorite/CreateFavoriteList?authToken=${authToken}`, { products: selectedItems, listName: tempName })
            dispatch(setFavoriteProducts(a.data.response))
            setIsEditing(false)
        } else {
            setErrorOpen(true)
        }
        setTempName("")
        setSelectedItems([])
    }

    const handleCancel = () => {
        setIsEditing(false)
        setTempName("")
        setSelectedItems([])
    }

    const toggleEdit = () => {
        setIsEditing(true)
        setTempName(tempName)
    }

    const handleSelect = (_event, value) => {
        const selectedOptions = value
        setSelectedItems(selectedOptions)
    }

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return
        }
        setErrorOpen(false)
    }

    return (
        <main className={classes.content}>
            <div className={classNames(classes.flexColumn, classes.wrapper)}>
                <Typography variant="h3" gutterBottom>{Localization.yourFavorites}</Typography>
            </div>
            <Box
                className={classes.listArea}
                style={{
                    position: "sticky",
                    top: isTablet ? 100 : 150,
                    zIndex: 98
                }}
            >
                <div style={{
                    display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: isMobile ? "wrap" : "no-wrap", paddingTop: 8, paddingBottom: 8
                }}
                >
                    {!isEditing && (
                        <Button
                            id="submit"
                            size="small"
                            variant="text"
                            type="submit"
                            onClick={toggleEdit}
                            onKeyDown={e => e.key === "Enter" && toggleEdit()}
                            style={{
                                display: "flex",
                                padding: "10px 12px",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "10px",
                                borderRadius: "4px",
                                background: "#F09057",
                                height: 31
                            }}
                        >
                            <Typography style={{
                                color: "#2D2D2D",
                                leadingTrim: "both",
                                textRdge: "cap",
                                fontFamily: "Arial",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontWeight: "700",
                                lineHeight: "16px" /* 100% */,
                                textTransform: "none"
                            }}
                            >
                                {Localization.newList}
                            </Typography>
                        </Button>
                    )}
                    {isEditing && (
                        <div style={{
                            display: "flex", alignItems: "center", marginBottom: 1, gap: isMobile ? 12 : 8, flexWrap: "wrap"
                        }}
                        >
                            <TextField
                                id="name"
                                type="filled"
                                value={tempName}
                                label={`${Localization.newName}`}
                                variant="standard"
                                onChange={handleNameChange}
                                sx={{
                                    "& .MuiInput-input": { padding: "0px!important", height: "auto!important" },
                                    width: "96px",
                                    "& .MuiInput-root::before": {
                                        borderBottom: "none"
                                    }
                                }}
                            />
                            <div style={{ display: "flex" }}>
                                <Autocomplete
                                    id="favoriteSelector"
                                    onChange={handleSelect}
                                    multiple
                                    options={idArray}
                                    className={classes.select}
                                    variant="standard"
                                    value={selectedItems}
                                    disableCloseOnSelect
                                    clearText={Localization.clear}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            InputProps={{
                                                ...params.InputProps,
                                                disableUnderline: true
                                            }}
                                            label={Localization.selectProducts}
                                            placeholder={Localization.productId}
                                            sx={{
                                                "& .MuiInputBase-root": {
                                                    height: "24px"
                                                },
                                                "&. .MuiInputBase-root-MuiInput-root": {
                                                    marginTop: 0
                                                },
                                                "& .MuiInput-root .MuiInput-input": {
                                                    padding: "0px"
                                                }
                                            }}
                                        />
                                    )}
                                    renderTags={value => (value.length > 0 ? (
                                        <Chip label={`${value.length}`} style={{ height: 24 }} />
                                    ) : null)}
                                />
                                <Tooltip title={Localization.save}>
                                    <Box
                                        tabIndex="0"
                                        role="button"
                                        onKeyDown={e => e.key === "Enter" && handleSave()}
                                        onClick={handleSave}
                                        style={{ display: "flex", alignItems: "flex-end" }}
                                    >
                                        <img src={CheckIcon} alt={Localization.save} style={{ width: "24px", height: "24px" }} />
                                    </Box>
                                </Tooltip>
                                <Tooltip title={Localization.cancel}>
                                    <Box
                                        tabIndex="0"
                                        role="button"
                                        onKeyDown={e => e.key === "Enter" && handleCancel()}
                                        onClick={handleCancel}
                                        style={{ display: "flex", alignItems: "flex-end" }}
                                    >
                                        <img src={ClearIcon} alt={Localization.cancel} style={{ width: "24px", height: "24px" }} />
                                    </Box>
                                </Tooltip>
                            </div>
                        </div>
                    )}
                    <Snackbar open={errorOpen} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
                        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
                            {Localization.errorCreatingFavoriteList}
                        </Alert>
                    </Snackbar>
                </div>
            </Box>
            {
                userLists?.map((list, index) => (
                    <Userlist
                        userLists={userLists}
                        list={list}
                        language={language}
                        isLoading={isLoading}
                        classes={classes}
                        theme={theme}
                        productsArray={userFavorites}
                        index={index}
                    />
                ))
            }
            <Box
                style={{
                    margin: `0 ${theme.spacing(3)}px`,
                    paddingTop: theme.spacing(6),
                    paddingRight: 16,
                    paddingLeft: 16,
                    paddingBottom: 8,
                    display: "flex",
                    justifyContent: "space-between",
                    backgroundColor: "#fafafa"
                }}
                id="products"
            >
                <Typography>
                    {!showNotInList ? Localization.allProducts : Localization.unlisted}
                </Typography>
                <div style={{ display: "flex" }}>
                    <Button
                        id="submit"
                        size="small"
                        variant="text"
                        type="submit"
                        onClick={() => setShowNotInList(!showNotInList)}
                        onKeyDown={e => e.key === "Enter" && setShowNotInList(!showNotInList)}
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "10px",
                            borderRadius: "4px",
                            background: "#F09057",
                            height: 24
                        }}
                    >
                        <Typography style={{
                            color: "#2D2D2D",
                            leadingTrim: "both",
                            textRdge: "cap",
                            fontFamily: "Arial",
                            fontSize: "12px",
                            fontStyle: "normal",
                            fontWeight: "700",
                            lineHeight: "12px" /* 100% */,
                            textTransform: "none"
                        }}
                        >
                            {showNotInList ? Localization.showAllFavorites : Localization.showUnlisted}
                        </Typography>
                    </Button>
                    <ProductViewToggleButton noMargin />
                </div>
            </Box>
            <Divider />
            <Products products={showNotInList ? notInList : products} missingProducts={isLoading ? [] : missingProducts} />
        </main>
    )
}

export default FavoriteProductsPage

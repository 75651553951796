import { mobileBreakpoint, tabletBreakpoint } from "../../../lib/helper/viewportHelper"
import { makeStyles } from "@mui/styles"

export default makeStyles(theme => ({
    container: {
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
        paddingTop: theme.spacing(8),
        maxWidth: "1920px"
    },
    newsWrapper: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center"
    },
    stepperWrapper: {
        maxWidth: 800,
        padding: theme.spacing(3)
    },
    productsWrapper: {
        flex: "0 0 100%"
    },
    stepper: {
        marginBottom: 20,
        marginTop: 10
    },
    noProductsBox: {
        flex: "0 1 1600px",
        margin: `0 ${theme.spacing(3)}px`,
        marginTop: theme.spacing(6),
        display: "flex",
        justifyContent: "space-between"
    },
    titleBox: {
        marginTop: 40,
        marginBottom: 30
    },
    youtubeVideo: {
        aspectRatio: "16 / 9",
        width: "100%"
    },
    newsTitle: {
        margin: 0,
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        fontWeight: 400,
        fontSize: "3rem",
        lineHeight: "1.167",
        letterSpacing: "0em",
        [theme.breakpoints.down(tabletBreakpoint)]: {
            fontSize: "2.2rem"
        },
        [theme.breakpoints.down(mobileBreakpoint)]: {
            fontSize: "1.8rem"
        }
    },
    newsPreamble: {
        margin: 0,
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        fontWeight: 400,
        fontSize: "1.5rem",
        lineHeight: 1.334,
        letterSpacing: "0em",
        [theme.breakpoints.between(tabletBreakpoint)]: {
            fontSize: "1.2rem"
        },
        [theme.breakpoints.down(mobileBreakpoint)]: {
            fontSize: "1.1rem"
        }
    }
}))

import useStyles from "./styles"
import Car from "./assets/car.svg"
import Phone from "./assets/phone.svg"
import { useLanguage } from "../../../lib/hooks/languageHooks"
import { strings as Localization } from "../../../lib/Localization"
import { setSearchQuery } from "../../../redux/searchReducer"
import { useGetBrandsQuery, useGetModelYearsQuery } from "../../../redux/services/productInfoApi"
import { useGetModelsQuery } from "../../../redux/services/broditWebApi"
import { useSteering } from "../../../redux/hook/userHooks"
import { tabletBreakpoint } from "../../../lib/helper/viewportHelper"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import React, { useEffect, useState, useRef } from "react"
import { Autocomplete, useMediaQuery } from "@mui/material"
import TextField from "@mui/material/TextField"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp"
import ClearIcon from "@mui/icons-material/Clear"
import CircularProgress from "@mui/material/CircularProgress"

function MultiSearchbar({
    vehicles = false,
    maxWidth = "200px",
    snap = "",
    show,
    onClose = () => { },
    onClick
}) {
    const dropdownRef = useRef(null)
    const classes = useStyles()
    const dispatch = useDispatch()
    const language = useLanguage()
    const steering = useSteering()
    const { data: brands } = useGetBrandsQuery({ language, steering: vehicles ? steering : null })
    const { data: allModels } = vehicles
        ? useGetModelsQuery({ language, steerings: [steering] })
        : useGetModelsQuery({ language })

    const [open, setOpen] = useState(show)
    const [brandOptions, setBrandOptions] = useState([])
    const [modelOptions, setModelOptions] = useState([])
    const [yearOptions, setYearOptions] = useState([])

    const [selectedBrand, setSelectedBrand] = useState(null)
    const [selectedModel, setSelectedModel] = useState(null)
    const [selectedYear, setSelectedYear] = useState(null)
    const [hover, setHover] = useState(false)
    const isMobile = useMediaQuery(`(max-width:${tabletBreakpoint}px)`)

    useEffect(() => {
        setOpen(show)
    }, [show])

    const { data: modelYears } = useGetModelYearsQuery({
        brand: selectedBrand,
        model: selectedModel,
        language: language,
        steering: steering
    })
    const navigate = useNavigate()

    const ArrowIcon = open ? ArrowDropUpIcon : ArrowDropDownIcon

    const getFullSelectionString = () => {
        let string = selectedBrand

        if (selectedModel) string += ` ${selectedModel}`
        if (selectedYear) string += ` ${selectedYear}`

        return string
    }

    const urlToFilterPage = () => `/${language}/filter`

    useEffect(() => {
        const isOtherBrandDevice = !vehicles && brandOptions.indexOf(selectedBrand) === 0
        setSelectedModel(null)
        setSelectedYear(null)

        if (allModels && !isOtherBrandDevice) {
            setModelOptions(allModels.filter(model => model.brand === selectedBrand).map(({ name }) => name))
        }

        if (isOtherBrandDevice) {
            dispatch(setSearchQuery({
                type: "deviceSearchbar",
                value: {
                    brands: [selectedBrand],
                    models: []
                },
                clearFilter: true
            }))

            setOpen(false)
            navigate(urlToFilterPage(), { replace: true })
        }
    }, [selectedBrand])

    useEffect(() => {
        setSelectedYear(null)
    }, [selectedModel])

    useEffect(() => {
        setYearOptions(modelYears)
    }, [modelYears])

    useEffect(() => {
        if (selectedModel && !vehicles) {
            dispatch(setSearchQuery({
                type: "deviceSearchbar",
                value: {
                    brands: [selectedBrand],
                    models: [selectedModel]
                },
                clearFilter: true
            }))

            setOpen(false)
            onClose()
            navigate(urlToFilterPage(), { replace: true })
        }
    }, [selectedModel])

    useEffect(() => {
        if (selectedYear && vehicles) {
            dispatch(setSearchQuery({
                type: "vehicleSearchbar",
                value: {
                    brands: [selectedBrand],
                    steering: [steering],
                    models: [selectedModel],
                    years: [selectedYear]
                },
                clearFilter: true
            }))

            setOpen(false)
            onClose()
            navigate(urlToFilterPage(), { replace: true })
        }
    }, [selectedYear])

    useEffect(() => {
        if (brands === undefined) return

        let newBrandOptions = []

        if (vehicles) {
            newBrandOptions = brands
                .filter(brand => brand.brandType.name === "Vehicles")
                .map(({ name }) => name)
        } else {
            const processedNames = new Set()

            brands.forEach(brand => {
                if (brand.brandType.name !== "Vehicles" && !processedNames.has(brand.name)) {
                    newBrandOptions.push(brand.name)
                    processedNames.add(brand.name)
                }
            })
        }

        setBrandOptions(newBrandOptions)
    }, [brands])

    useEffect(() => {
        const handleClickOutside = event => {
            if (event.target.classList.value.length === 0) return
            if (dropdownRef.current
                && !dropdownRef.current.contains(event.target)
                && !event.target.classList.contains("MuiAutocomplete-option")
                && !event.target.classList.contains("MuiAutocomplete-listbox")
                && !event.target.classList.contains("MuiAutocomplete-dropdown")
                && !event.target.classList.contains("MuiAutocomplete-wrapper")
                && !event.target.classList.contains("MuiInputBase-input")
                && !event.target.classList.contains("MuiBox-root")
                && !event.target.classList.contains("MuiSvgIcon-root")
                && !event.target.classList.contains("MuiAutocomplete-input")
                && !event.target.classList.contains("makeStyles-dropdownButton-60")
                && !event.target.classList.contains("makeStyles-dropdownButton-64")
                && !event.target.classList.contains("makeStyles-truncatedSearchString-65")
                && !event.target.classList.contains("makeStyles-option-69")
                && !event.target.classList.contains("MuiAutocomplete-endAdornment")
            ) {
                setOpen(false)
                onClose()
            }
        }

        document.addEventListener("mousedown", handleClickOutside)

        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [])

    const getPlaceholderString = () => (vehicles ? Localization.searchOnVehicle : Localization.searchOnDevice)

    const snapStyle = () => {
        switch (snap) {
            case "left":
                return { left: 0 }
            case "center":
                return { left: "50%", transform: "translateX(-50%)" }
            default:
                return {}
        }
    }

    const clearFilters = e => {
        e.stopPropagation()
        setSelectedYear(null)
        setSelectedModel(null)
        setSelectedBrand(null)
    }

    const anyFilterSelected = selectedBrand !== null

    return (
        <div
            role="button"
            tabIndex={0}
            className={classes.wrapper}
            id={vehicles ? "vehicle-searchbar" : "device-searchbar"}
            ref={dropdownRef}
            onClick={() => isMobile && onClick()}
            onKeyDown={event => {
                if (event.key === "Enter") {
                    onClick()
                }
            }}
        >
            <button disabled={!brands} id="dropdownButton" onClick={() => { setOpen(!open) }} type="button" className={classes.dropdownButton} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
                <div className={classes.buttonLeftWrapper}>
                    <div className={classes.iconWrapper}>
                        <img loading="lazy" src={vehicles ? Car : Phone} width="19px" height="16px" alt="Car icon" />
                    </div>
                    <span className={classes.truncatedSearchString} style={{ maxWidth: maxWidth }}>
                        {selectedBrand ? getFullSelectionString() : getPlaceholderString()}
                    </span>
                </div>
                <div className={classes.buttonRightWrapper}>
                    {anyFilterSelected
                        && (
                            <div className={`${classes.clearButton} ${hover && classes.hover}`} role="button" tabIndex={0} onClick={hover ? clearFilters : undefined} onKeyDown={e => { if (e.key === "Enter") clearFilters() }}>
                                <ClearIcon style={{ width: "16px", height: "16px", color: "#2d2d2d" }} />
                            </div>
                        )}
                    {!brands ? (
                        <CircularProgress style={{
                            width: "16px", height: "16px", paddingLeft: 4, paddingRight: 4
                        }}
                        />
                    ) : <ArrowIcon style={{ color: "#2d2d2d" }} />}
                </div>
            </button>
            {open
                && (
                    <div className={classes.dropdown} style={snapStyle()}>
                        <Autocomplete
                            id={`${vehicles ? "vehicle" : "device"}-autocomplete-search`}
                            value={selectedBrand}
                            options={brandOptions}
                            onChange={(e, value) => setSelectedBrand(value)}
                            renderInput={params => <TextField {...params} label={Localization.brands} variant="outlined" />}
                        />
                        <Autocomplete
                            id={`${vehicles ? "vehicle" : "device"}-model-autocomplete-search`}
                            value={selectedModel}
                            disabled={!selectedBrand || modelOptions.length === 0}
                            options={modelOptions}
                            onChange={(e, value) => setSelectedModel(value)}
                            renderInput={params => <TextField {...params} label={Localization.models} variant="outlined" />}
                        />
                        {vehicles
                            && (
                                <Autocomplete
                                    id="vehicle-year-autocomplete-search"
                                    value={selectedYear}
                                    disabled={!selectedModel}
                                    options={yearOptions}
                                    onChange={(e, value) => setSelectedYear(value)}
                                    renderInput={params => <TextField {...params} label={Localization.modelYears} variant="outlined" />}
                                />
                            )}
                    </div>
                )}
        </div>
    )
}

export default MultiSearchbar
